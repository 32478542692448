import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import {useNavigate, useParams } from 'react-router-dom'
import MiniDrawer from '../../../components/drawer'
import CouponandOffersettings from './CouponandOffersettings'
import Datatablecoupons from './Datatablecoupons'
import { PlaylistAddRounded } from '@mui/icons-material'



const Couponindex = () => {

  const navigate = useNavigate()
    
    const params = useParams()

    const addCoupons = () => {
      navigate(`/settings/addcoupons/${params.id}`)
  }

  return (
    <>
    <Box className='companies'>
            <MiniDrawer menuOrder={23} submenuOrder={26}/>
            <Box component="section" className="contentWraper">
                <Box className='headingBox'>
                    <Typography component='h4' variant='h4' className='Submenu-page-heading formTitle'>Settings</Typography>

                    <Button variant="contained" className='addbtn' onClick={() => addCoupons()}> <PlaylistAddRounded className='fz-18 mr-5' /> Add Coupon</Button>
                    
                </Box>
                <Box className='whiteContainer' sx={{minHeight:"80vh"}}>
                  
                  <Datatablecoupons/>
                </Box>
            </Box>
        </Box>
    </>
  )
}

export default Couponindex;

