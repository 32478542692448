import { PlaylistAddRounded } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import MiniDrawer from '../../../../components/drawer'
import NewsSliderTable from './NewsSliderTable'

export default function NewsSliders() {
    let navigate = useNavigate()
    const role = JSON.parse(localStorage.getItem("roledata"))

    const AddHomeTab = () => {
         navigate('/addnewsslider');
    }
    return (
        <Box className='companies'>
            <MiniDrawer menuOrder={11} submenuOrder={18} />
            <Box component="section" className="contentWraper">
                <Box className='headingBox'>
                    <Typography component='h4' variant='h4' className='Submenu-page-heading formTitle'>News Slider Setting</Typography>
                    {role.UserRoles == null ? <Button variant="contained" className='addbtn' onClick={() => AddHomeTab()}> <PlaylistAddRounded className='fz-18 mr-5' /> Add News Slider</Button>
                        : role.UserRoles != null && role.UserRoles.settings.Addsettings &&
                        <Button variant="contained" className='addbtn' onClick={() => AddHomeTab()}> <PlaylistAddRounded className='fz-18 mr-5' /> Add News Slider </Button>

                    }                </Box>
                <NewsSliderTable />
            </Box>
        </Box>
    )
}