import React, { useEffect, useRef } from 'react';
import { Box, Button, Card, FormControl, Grid, IconButton, Typography } from '@mui/material';
import { AddOutlined, Close } from '@mui/icons-material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
// import './community.css'
const Add_Video = ({ parentFunc,setVideoAddError }) => {
    const [video, setVideo] = React.useState([]);
    const [error, setError] = React.useState({ video: "", image: "" });

    const inputRef = useRef(null);
    const handleAddClick = () => {
        setVideo(prevVideo => [...prevVideo, { imgpreview: "", imgraw: "", vdopvw: '', vdoraw: '' }]);
    };

    const handleImageChange = (e, index) => {
        const selectedFile = e.target.files[0];

        // Check if a file is selected
        if (!selectedFile) {
            // setError({ ...error, image: "Please select an image file." });
            setVideoAddError("Please select an image file")
            return;
        }

        // Check if the file size exceeds 1 MB (in bytes)
        const maxSize = 1 * 1024 * 1024; // 1 MB in bytes
        if (selectedFile.size > maxSize) {
            // setError({ ...error, image: "Image should be less than 1 MB" });
            setVideoAddError("Image should be less than 1 MB")
            return;
        }

        setVideo(prevVideo => {
            const addVideo = [...prevVideo];
            addVideo[index] = {
                imgpreview: URL.createObjectURL(selectedFile),
                imgraw: selectedFile
            };
            return addVideo;
        });

        // Clear image error if validation passes
        setError({ ...error, image: "" });
    };


    const resetThumbnailImage = (index) => {
        setVideo(prevVideo => {
            const removeImage = [...prevVideo];
            removeImage[index] = {
                ...removeImage[index],
                imgpreview: '',
                imgraw: '',
            };
            return removeImage;
        });
    };

    const handleVideoChange = (e, index) => {
        console.log(video)
        if(!video[0].imgpreview){
            // setError({ ...error, image: "Please select an Thumbnail image." });
            setVideoAddError("Please select an Thumbnail image.")
            return;
        }
        const selectedFile = e.target.files[0];

        // Check if a file is selected
        if (!selectedFile) {
            // setError({ ...error, video: "Please select a video file." });
            setVideoAddError("Please select a video file.")
            return;
        }

        // Check if the file size exceeds 25 MB (in bytes)
        const maxSize = 25 * 1024 * 1024; // 25 MB in bytes
        console.log(selectedFile)
        if (selectedFile.size > maxSize) {
            // setError({ ...error, video: "Video should be less than 25 MB" });
            setVideoAddError("Video should be less than 25 MB")

            return;
        }

        setVideo(prevVideo => {
            const updatedVideo = [...prevVideo];
            updatedVideo[index] = {
                ...updatedVideo[index],
                vdopvw: URL.createObjectURL(selectedFile),
                vdoraw: selectedFile,
            };
            return updatedVideo;
        });

        // Clear video error if validation passes
        setError({ ...error, video: "" });
    };


    const handleRemoveVideo = (e, index) => {
        setVideo(prevVideo => {
            const updatedVideo = [...prevVideo];
            updatedVideo[index] = {
                ...updatedVideo[index],
                vdopvw: '',
                vdoraw: '',
            };
            return updatedVideo;
        });
    };

    useEffect(() => {
        parentFunc(video);
    }, [video]);

    return (
        <Box className={video.length ? "d-flex align-items-end flex-wrap" : ''}>
          
                <Box className="flex-1">
                    {video.map((x, i) => (
                        <FormControl key={i} className='' fullWidth >
                            <Grid container className=" mb-10" columnSpacing={2} rowSpacing={2} style={{display:"flex"}}>

                                <Grid item xs={12} sm={6} md={6} lg={4} xl={3} >
                                    <Box className='d-flex'>
                                        <Typography className='fz-13  mb-10 mr-10'>{i + 1}</Typography>
                                        <Box>
                                            {x.imgpreview === '' || x.imgpreview === undefined ?
                                                <Button className="multiple_upload_btn" variant="contained" component="label">
                                                    <Box ><FileUploadOutlinedIcon className="fz-18 mr-5" />Thumbnail image </Box>
                                                    <input accept={"image/png, image/jpg,image/jpeg"} onChange={(e) => handleImageChange(e, i)} hidden type="file" />
                                                </Button> :
                                                <Box className="form-box upload-img">
                                                    <Card style={{ width: '200px' }}>
                                                        <Typography component='div' variant='div'>
                                                            <Button className='close' onClick={(e) => resetThumbnailImage(e, i)}><Close /></Button>
                                                        </Typography>
                                                        <Box  >
                                                            {x.imgpreview === '' || x.imgpreview === undefined ? null :
                                                                <img src={x.imgpreview} alt='' height={155} width={195} border={0} />
                                                            }
                                                        </Box>
                                                    </Card>
                                                </Box>
                                            }
                                        </Box>
                                    </Box>
                                    {error.image && <p style={{ color: 'red' }} className="fz-13 m-0">{error.image}</p>}
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                    <Box className="" >
                                        {x.vdopvw === '' || x.vdopvw === undefined ?
                                        
                                            <Button className="multiple_upload_btn" variant="contained" component="label">
                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload video </Box>
                                                <input id="video-upload" accept="video/*" onChange={(e) => handleVideoChange(e, i)} hidden type="file" />
                                            </Button> :
                                            <Grid container columnSpacing={2} rowSpacing={2} className="">
                                                <Grid item xs={6} sm={4} md={4} lg={4} >
                                                    <Box   >
                                                        <Button className='close_icon' onClick={(e) => handleRemoveVideo(e, i)}><Close /></Button>
                                                        <video style={{marginRight:"15px"}} controls className='' src={x.vdopvw} height={155} width={195} />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Box>
                                    {error.video && <p style={{ color: 'red' }} className="fz-13 m-0">{error.video}</p>}
                                </Grid>
                            </Grid>
                        </FormControl>

                    ))}
                </Box>
         
            <Button className='theme-btn' onClick={handleAddClick}>+ Add Video</Button>
        </Box>

    );
};

export default Add_Video;
