

import { PlaylistAddRounded } from '@mui/icons-material'
import { Box, Button, Typography, TextField } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import MiniDrawer from '../../../components/drawer'
import { usePlacesWidget } from "react-google-autocomplete";
// import { Button, Card, Box,  , Typography, TextareaAutosize, CardActions, Menu, RadioGroup, Radio, IconButton, Tooltip } from '@mui/material';

export default function AutoComplete({cityName, setCityName, lat, setLat,lng, setLng}) {
    
    const { ref, autocompleteRef } = usePlacesWidget({
        // need to change this API key 
        apiKey: 'AIzaSyB-U-riOWcHLeZOeh197bv_RGfF4mF6Jj8',
        onPlaceSelected: (place) => {
            console.log(place, 'placeplace');
            setCityName(place.formatted_address)
            console.log(place.geometry.location.lat(),"place.geometry.location.lat()", place.geometry.location.lng())
            setLat(place.geometry.location.lat());
            setLng(place.geometry.location.lng());
            // setLoc_coordinates(prevState => ({
            //     ...prevState,
            //     coordinates: [place.geometry.location.lat(), place.geometry.location.lng()]
            // }));        
        },
        options: {
            types: "(regions)" | 'establishment'
        },
    });


    return (
        <TextField
            id="filled-size-small"
            variant="outlined"
            size="small"
            name='specialization'
            className='textfield'
            placeholder='location'
            inputRef={ref}
        />
    );
}

