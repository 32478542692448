import * as React from 'react';
import Box from '@mui/material/Box';
import { TableBody, Typography, Grid, Paper, Button, TextField, Select, InputAdornment, FormControl, Tooltip, Card, Input, Divider, CardActionArea, Menu, TableContainer, Table, TableHead, TableRow, TableCell, tableCellClasses } from '@mui/material';
import { PlaylistAddRounded, InfoOutlined, CloseOutlined, DataArraySharp, EditOutlined, Close, Add, SearchOutlined, GridView, ListAlt, CheckCircle, Verified } from '@mui/icons-material';
import dayjs from 'dayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DOMPurify from 'dompurify';
import { styled } from '@mui/material/styles';
import TablePagination from '@mui/material/TablePagination';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import MenuItem from '@mui/material/MenuItem';
import { useSnackbar } from 'notistack'
import Switch, { SwitchProps } from '@mui/material/Switch';
import CardContent from '@mui/material/CardContent';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { useNavigate } from 'react-router-dom';
import { CiLink } from "react-icons/ci";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Navigation } from 'swiper/modules';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import FormControlLabel from '@mui/material/FormControlLabel';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import PersonIcon from '@mui/icons-material/Person';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { DeleteOutline } from '@mui/icons-material';






import moment from 'moment';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >
                    <CloseIcon className='fz-18' />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};
const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 40,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 0,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 18.5,
        height: 18.5,
    },
    '& .MuiSwitch-track': {
        borderRadius: 18.5 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));
BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

export default function FormPropsTextFields() {
//UseState's:
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const navigate = useNavigate()
    const auth = JSON.parse(localStorage.getItem('userData'))
    const { enqueueSnackbar } = useSnackbar()
    const [newdata, setNewdata] = React.useState([])
    const [open, setOpen] = React.useState(false);
    const [openview, setOpenview] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(12);
    const [total, setTotal] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [search, setSearch] = React.useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const menuopen = Boolean(anchorEl);
    const [alldata, setAlldata] = React.useState('');
    const [cat, setCat] = React.useState([]);
    const [view, setView] = React.useState(false);
    const [deleteid, setDeleteId] = React.useState('');
    const [catid, setCatId] = React.useState('all');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    React.useEffect(() => {
        getCategory()
    }, [])

    const getCategory = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `category/GetCategory?size=100&page=0&order=asc`, { headers: headerlocal })
            .then((res) => {
                console.log(res.data)
                setCat(res.data.category)
            }).catch((error) => {
                console.log(error.response, "error");
            })
    }

    const handleClick = (event, data) => {
        setAlldata(data)
        setAnchorEl(event.currentTarget);
    };

    const handleClose_menu = () => {
        setAnchorEl(null);
    };

    // React.useEffect(() => {
    //     getCommunity()
    // }, [rowsPerPage, page, search, catid])

    // const getCommunity = async () => {
    //     await axios.get(process.env.REACT_APP_BASE_URL + `Community/getCommunity?size=${rowsPerPage}&page=${page}&search=${search}&category_id=${catid}`, { headers: headerlocal }).then((res) => {
    //         setNewdata(res.data.community)
    //         setTotal(res.data.totalcount)
    //         console.log(res.data, "community");
    //     }).catch((error) => {
    //         console.log(error, "error in service get");
    //     })
    // }
    React.useEffect(() => {
        getCommunity()
    }, [rowsPerPage, page, search, catid])

    const getCommunity = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `Community/getCommunity?size=${rowsPerPage}&page=${page}&search=${search}&category_id=${catid}`, { headers: headerlocal }).then((res) => {
            console.log(res.data, "community get");
            setNewdata(res.data.community)
            setTotal(res.data.totalcount)
            // console.log(res.data, "community");
        }).catch((error) => {
            console.log(error, "error in community get");
        })
    }


    const handleDeleteService = async () => {
        await axios.put(process.env.REACT_APP_BASE_URL + `community/deleteCommunity/${deleteid}`, {}, { headers: headerlocal })
            .then((res) => {
                enqueueSnackbar("Community deleted successfully", { variant: 'success' })
                getCommunity()
                handleClose()
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleClickOpen = () => {
        setAnchorEl(null);
        setDeleteId(alldata._id)
        setOpen(true);
    };

    const handleClose = () => {
        setDeleteId('')
        setOpen(false);
    };

    const handleMore = () => {
        navigate(`/community/community-details/desc/${alldata._id}`, { state: alldata._id })
    }

    const handleEdit = () => {
        let val = {
            data: alldata
        }
        navigate("/editcommunity", { state: val })
    }

    const handleView = (data) => {
        // console.log(data,"mo bando")
        // navigate(`/community_details/${data.coummunity_name}`, { state: alldata.coummunity_name })
        navigate(`/community_details/${data.coummunity_name}`, { state: data})
    }

    const handleCopy = (e, id) => {
        e.preventDefault();
        const url = generateUrl(id);
        copyToClipboard(url);
    };

    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            enqueueSnackbar('Link copied to clipboard.', { variant: 'success' })
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    const generateUrl = (id) => {
        return `${id}`;
    };

    const handleRemove = () => {
        setCatId('all')
        setSearch('')
        setPage(0)
    }

    const sanitizedContent = DOMPurify.sanitize(alldata.short_description, { ALLOWED_TAGS: ['img'] });

    const handleClickClose = () => {
        setOpenview(false)
        setAlldata('')
    }

    const handleChangeStatus = async (e, data, type) => {
        // let veried = data.createdby[0].isSubscribed

        // if (e.target.checked && !veried) {
        //     enqueueSnackbar('Please check the email for the payment link and complete the process.', { variant: 'error' });
        // } else {
        if (type === 'Approve') {
            const body = {
                approve_community: e.target.checked,
            };
            console.log(body, "approve");
            try {
                 await axios.put(process.env.REACT_APP_BASE_URL + `community/Updateapprove_community/${data._id}`, body, { headers: headerlocal });
                getCommunity();
            } catch (error) {
                // enqueueSnackbar(error.response.data && error.response.data.error, { variant: 'error' })
                // console.error("Error while updating primary event", error);
            }
        }
    // }

    };

    const Gridview = () => {
        setView(false)
    }
    const Listview = () => {
        setView(true)
    }

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
   

        const items = Array.from(newdata);

        const [reorderedItem] = items.splice(result.source.index, 1);

        items.splice(result.destination.index, 0, reorderedItem);
        var fromvar = [...items]
        
        
        // var intial = fromvar[result.source.index]
        var final = fromvar[result.destination.index]
        var before = fromvar[result.destination.index - 1]
        var after = fromvar[result.destination.index + 1]
        let currElIndexNumber = 0;

        if (before === undefined || before === "undefined") {
            currElIndexNumber = after.index_position + 512;
            console.log("called1",currElIndexNumber)
        } else if (after === undefined || after === "undefined") {

            
            currElIndexNumber = before.index_position - 512;
            console.log("called1",currElIndexNumber)
        } else if (before.setService === true && after.setService === false) {
            console.log("called1")

            currElIndexNumber = before.index_position - 512;
        } else if (before.setService === false && after.setService === true) {
            console.log("called1")

            currElIndexNumber = after.index_position + 512;

        } else if (before.setService === false && after.setService === true) {
            console.log("called1")

            currElIndexNumber = before.index_position - 512;
        } else if (before.setService === true && after.setService === false) {
            console.log("called1")

            currElIndexNumber = after.index_position + 512;
        }
        else {
            console.log("called1")

            currElIndexNumber = Math.floor((before.index_position + after.index_position) / 2);
        }

        setNewdata(fromvar);

        var body = {
            index_position: currElIndexNumber
        }

        console.log(body,"o")

        axios.put(process.env.REACT_APP_BASE_URL + `Community/updateCommunitypositions/${final._id}`, body, { headers: headerlocal })
            .then((res) => {
                getCommunity()
            }).catch((err) =>
                console.log(err.response, "drag & drop")
            )
    }

    const EditData = (row) => {
        console.log(row, 'rowrow');
        let obj = {
            data: row
        }
        navigate("/editteacher", { state: obj })
    }

    const handleOpen = (id) => {
        setDeleteId(id)
        setOpen(true)
    }

    return (
        <>
        {!view?(
            <Box className=' p-20'>
            <>
                <Box className='flex-center flex-wrap mb-20 '>
                <Box className="mr-10 mb-5">
                <Tooltip title="Grid View">
                  <Button
                    className={view === false ? "active_tab" : "inactive_tab"}
                    onClick={Gridview}
                  >
                    <GridView />
                  </Button>
                </Tooltip>
                <Tooltip title="List View">
                  {" "}
                  <Button
                    className={view === true ? "active_tab" : "inactive_tab"}
                    onClick={Listview}
                  >
                    <ListAlt />
                  </Button>
                </Tooltip>
              </Box>
                    <Box component='div' className='DTsearch mr-10'>
                        <FormControl variant="standard">
                            <Typography component='div' className='label mr-10'>Search :</Typography>
                            <TextField
                                id="filled-size-small"
                                variant="outlined"
                                size="small"
                                value={search}
                                className='textfield search'
                                onChange={(e) => { setSearch(e.target.value); setPage(0) }}
                                placeholder='Search Here...'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {search !== '' && (
                                                <IconButton onClick={handleRemove} size="small">
                                                    <CloseOutlined className='fz-18' />
                                                </IconButton>
                                            )}
                                            <SearchOutlined className='fz-18' />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Box>
                    <Box className='flex-center'>
                        <Typography component="div" className="label mb-0 mr-10">Category: </Typography>
                        <FormControl fullWidth className='minw200' >
                            <Select className="select"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                onChange={(e) => setCatId(e.target.value)}
                                value={catid ? catid : 'all'}
                            >
                                <MenuItem value={'all'}>All</MenuItem>
                                {cat && cat.length > 0 && cat.map((data) => (
                                    <MenuItem value={data._id}>{data.category_title}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>


                    {search !== '' ?
                        <Button variant="contained" className='addbtn theme-btn ml-10' onClick={handleRemove} >Reset</Button>
                        : ""}
                </Box>
            </>

            <Box >
                <Grid container columnSpacing={2} rowSpacing={2} className="servicesList otherList" >
                    {newdata && newdata.length > 0 ? newdata.map((data, index) => {
                        return (
                            <Grid key={index} item xs={12} sm={6} md={6} lg={4} xl={3} sx={{ marginTop: 2 }}>
                                <Card className="cardGrid" >
                                    <IconButton aria-label="more" id="long-button"
                                        aria-controls={menuopen ? 'long-menu' : undefined}
                                        aria-expanded={menuopen ? 'true' : undefined}
                                        aria-haspopup="true"
                                        onClick={(e) => handleClick(e, data)}
                                        className='vertical_menu'>
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                        id="long-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'long-button',
                                        }}
                                        anchorEl={anchorEl}
                                        open={menuopen}
                                        onClose={handleClose_menu}
                                        PaperProps={{
                                            style: {
                                                width: '15ch',
                                            },
                                        }}
                                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    >
                                        <MenuItem className='menuitems' onClick={() => handleView(data._id)}><VisibilityIcon className='fz-16 mr-5' />View Timeline</MenuItem>
                                        <MenuItem className='menuitems' onClick={() => handleEdit()}><EditIcon className='fz-16 mr-5' />Edit</MenuItem>
                                        <MenuItem className='menuitems' onClick={(e) => handleClickOpen(e, data._id)}><DeleteIcon className='fz-14 mr-5' />Delete</MenuItem>
                                        <MenuItem className='menuitems' onClick={(e) => handleMore(e, data._id)}><MoreHorizIcon className='fz-14 mr-5' />More</MenuItem>
                                        <MenuItem className='menuitems' onClick={(e) => handleCopy(e, data.unique_link)}><CiLink className='fz-14 mr-5' />Copy URL</MenuItem>


                                    </Menu>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <div className="">
                                            <Swiper
                                                modules={[Navigation, Pagination]}
                                                className="mySwiper "
                                                slidesPerView={1}
                                                pagination={{
                                                    clickable: true,
                                                }}
                                            >
                                                {data && data.allimages && data.allimages?.length > 0 && data.allimages.map((tdata, i) => (
                                                    <SwiperSlide key={i}> <img width={'100%'} height={250} alt='Pic' src={process.env.REACT_APP_BASE_URL + `${tdata}`} /></SwiperSlide>
                                                )
                                                )}
                                            </Swiper>
                                        </div>
                                    </Grid>

                                    <CardContent>
                                    {data.createdby[0]._id === auth._id ? '' :
                                        <Box className='flex-center' >
                                            <Typography component='div' variant='body1' className='fz-14 mr-5 semibold '>Approved:</Typography>
                                            <FormControlLabel className='m-0' style={{ display: 'flex', alignItems: 'center' }} control={<Switch size="small" onChange={(e) => handleChangeStatus(e, data, 'Approve')} checked={data.approve_community} />} />
                                        </Box>}
                                        <Box className=' d-flex align-items-center justify-content-between' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography noWrap className='semibold title fz-18' variant="body1" component="div">{data.coummunity_name}</Typography>
                                            <Typography component='div' variant='body1' className='fz-14' style={{ display: 'flex', alignItems: 'center' }}><Verified className='fz-18' style={{ color: '#1492fd' }} /></Typography>
                                        </Box>

                                        {/* <Box className='mb-10 d-flex align-items-start justify-content-between' >
                                                <Box className='' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Typography component='div' variant='body1' className='fz-14 semibold'>Specialization: </Typography>
                                                    <Typography component='div' variant='body1' className='fz-14 '>{data && data.coummunity_title}</Typography>
                                                </Box>
                                            </Box> */}

                                        {data && data.short_description ?
                                            <Typography component='div' variant='body1' className='fz-14 ' >{data && data.short_description.slice(0, 50) + "..."}</Typography> :
                                            <Typography component='div' variant='body1' className='fz-14 '>There is no description</Typography>}

                                    </CardContent>
                                </Card>
                            </Grid>
                        )
                    }) : <Grid item xs={12} sm={12} md={12} lg={12}>
                        <p className='text-center semibold'>No data found</p></Grid>}
                </Grid>
                <Dialog open={open} keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                    className='custom_modal'>
                    <DialogTitle>Would you like to delete Community?</DialogTitle>
                    <DialogActions>
                        <Button onClick={handleClose} className='cancel_btn'>No</Button>
                        <Button onClick={handleDeleteService} className='theme-btn'>Yes</Button>
                    </DialogActions>
                </Dialog>
            </Box>

            <TablePagination
                rowsPerPageOptions={[8, 12, 16, 20, 24]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                className='DT-pagination'
            />        
        </Box>
        ):
        <Box className=' p-20'>
            
                <Box className='flex-center flex-wrap mb-20 '>

                    <Box className="mr-10 mb-5">
                        <Tooltip title="Grid View">
                        <Button
                            className={view === false ? "active_tab" : "inactive_tab"}
                            onClick={Gridview}
                        >
                            <GridView />
                        </Button>
                        </Tooltip>
                        <Tooltip title="List View">
                        {" "}
                        <Button
                            className={view === true ? "active_tab" : "inactive_tab"}
                            onClick={Listview}
                        >
                            <ListAlt />
                        </Button>
                        </Tooltip>
                    </Box>

                    <Box component='div' className='DTsearch mr-10'>
                        <FormControl variant="standard">
                            <Typography component='div' className='label mr-10'>Search :</Typography>
                            <TextField
                                id="filled-size-small"
                                variant="outlined"
                                size="small"
                                value={search}
                                className='textfield search'
                                onChange={(e) => { setSearch(e.target.value); setPage(0) }}
                                placeholder='Search Here...'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {search !== '' && (
                                                <IconButton onClick={handleRemove} size="small">
                                                    <CloseOutlined className='fz-18' />
                                                </IconButton>
                                            )}
                                            <SearchOutlined className='fz-18' />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Box>

                    <Box className='flex-center'>
                        <Typography component="div" className="label mb-0 mr-10">Category: </Typography>
                        <FormControl fullWidth className='minw200' >
                            <Select className="select"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                onChange={(e) => setCatId(e.target.value)}
                                value={catid ? catid : 'all'}
                            >
                                <MenuItem value={'all'}>All</MenuItem>
                                {cat && cat.length > 0 && cat.map((data) => (
                                    <MenuItem value={data._id}>{data.category_title}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                    {search !== '' ?
                        <Button variant="contained" className='addbtn theme-btn ml-10' onClick={handleRemove} >Reset</Button>
                        : ""}
                </Box>
            

            <Box >


                <Box>
                <TableContainer component={Paper} id="table">
                    <Table
                    sx={{ minWidth: 700 }}
                    aria-label="customized table"
                    className=""
                    >
                    <TableHead className="DT-head">
                        <TableRow className="">
                        {/* style={{ minWidth: 150 }} align="center" className='tbl_head' */}
                        <TableCell
                            align="center"
                            className="TRow DT-checkbox"
                            style={{ minWidth: 100, padding: "6px 16px" }}
                        >
                            Drag row
                        </TableCell>

                        <TableCell
                            align="center"
                            className="TRow DT-checkbox"
                            style={{ minWidth: 200, padding: "6px 16px" }}
                        >
                            Community Image
                        </TableCell>
                        <TableCell
                            align="left"
                            className="TRow DT-checkbox"
                            style={{ minWidth: 200, padding: "6px 16px" }}
                        >
                            Community Name
                        
                        </TableCell>

                        <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 200, padding: '6px 16px' }}> Community Title</TableCell>
                        {/* <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 200, padding: '6px 16px' }}>Published By</TableCell> */}
                        
                        <TableCell align="center" className='TRow DT-checkbox' style={{ minWidth: 200, padding: '6px 16px' }}>Approved</TableCell>
                        <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 200, padding: '6px 16px' }}>Description</TableCell>
                        <TableCell align="center" className='TRow DT-checkbox' style={{ minWidth: 200, padding: '6px 16px' }}>Action</TableCell>
                        
                        

                        {/* <TableCell
                            align="left"
                            className="TRow DT-checkbox"
                            style={{ minWidth: 200, padding: "6px 16px" }}
                        >
                            Publish Date
                        </TableCell> */}

                        
                        </TableRow>
                    </TableHead>

                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId="characters">
                        {(provided) => (
                            <TableBody
                            className="characters "
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            >
                            {newdata && newdata?.length > 0 ? (
                                newdata.map((row, i) => (
                                <Draggable
                                    key={row._id}
                                    draggableId={row._id}
                                    index={i}
                                >
                                    {(provided) => (
                                    <>
                                        <TableRow
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className=""
                                        >
                                        <TableCell
                                            className="DT-row"
                                            style={{ width: 100 }}
                                            align="center"
                                        >
                                            <DragHandleIcon />
                                        </TableCell>

                                        <TableCell align="center" className='DT-row'>
                                            {row.image
                                            ? <img className="cat_image" src={process.env.REACT_APP_BASE_URL + row.image} alt='' height={50} width={50} />
                                            : <PersonIcon style={{ height: 50, width: 50 }} />}
                                        </TableCell>

                                        {row && row.coummunity_name ? (
                                            <TableCell
                                            className="DT-row"
                                            align="left"
                                            style={{ minWidth: 200 }}
                                            >
                                            {row && row.coummunity_name}
                                            {row && row.primary === true ? (
                                                <Typography
                                                component="span"
                                                variant="body1"
                                                className="fz-14 "
                                                >
                                                {" "}
                                                <Verified
                                                    className="fz-18"
                                                    style={{ color: "#1492fd" }}
                                                />
                                                </Typography>
                                            ) : (
                                                ""
                                            )}
                                            </TableCell>
                                        ) : (
                                            <TableCell
                                            className="DT-row"
                                            style={{ minWidth: 200 }}
                                            >
                                            -
                                            </TableCell>
                                        )}


                                            {row && row.coummunity_title ? (
                                            <TableCell
                                            className="DT-row"
                                            align="left"
                                            style={{ minWidth: 200 }}
                                            >
                                            {row && row.coummunity_title}
                                            </TableCell>
                                        ) : (
                                            <TableCell
                                            className="DT-row"
                                            style={{ minWidth: 200 }}
                                            >
                                            -
                                            </TableCell>
                                        )}

                                        {/* <TableCell
                                            className="DT-row"
                                            align="left"
                                            style={{ minWidth: 200 }}
                                            >
                                            {row && row.Book_published_by}
                                        </TableCell> */}

                                {!(row.createdby[0]._id === auth._id) ? (
                                <StyledTableCell
                                align="center"
                                className="DT-row"
                                >
                                    <FormControlLabel
                                            className="m-0"
                                            style={{
                                                display: "flex",
                                                // alignItems: "center",
                                                justifyContent: "center", // Center horizontally
                                                alignItems: "center", // Center vertically
                                                height: "100%", 
                                            }}
                                            control={
                                                <Switch
                                                size="small"
                                                onChange={(e) =>
                                                    handleChangeStatus(
                                                    e,
                                                    row,
                                                    "Approve"
                                                    )
                                                }
                                                checked={row.approve_community}
                                                sx={{ m: 1 }}
                                                />
                                            }
                                            />
                                </StyledTableCell>
                                ) : (
                                <StyledTableCell
                                //   className="DT-row"
                                style={{ minWidth: 200,
                                    display: "flex",
                                                // alignItems: "center",
                                                justifyContent: "center", // Center horizontally
                                                alignItems: "center", // Center vertically
                                            
                                }}
                                >
                                <p>Not assigned</p>
                                </StyledTableCell>
                                )}
                                        
                                        
                                        

                                        
                                        {row && row.short_description ? (
                                <TableCell
                                className="DT-row"
                                style={{ minWidth: 200 }}
                                >
                                    {row &&
                                    row.short_description.slice(0, 50) +
                                        "..."}
                                </TableCell>
                                                ) : (
                                <TableCell
                                className="DT-row"
                                style={{ minWidth: 200 }}
                                >
                                    There is no description
                                </TableCell>
                                            )}

                                        
                                            <TableCell align="center" className='DT-row' style={{ cursor: 'pointer'}}>
                                        <Box className="flex-center justify-content-center actionbtns">
                                        <Tooltip title="View Timeline">
                                                <Box>
                                                    <VisibilityIcon className='icons text-grey' onClick={() => handleView(row)} />
                                                </Box>
                                            </Tooltip>
                                            <Tooltip title="Edit">
                                                <Box className='a_tag' onClick={() => EditData(row)}>
                                                    <EditOutlinedIcon className='icons text-grey' />
                                                </Box>
                                            </Tooltip>
                                            <Tooltip title="Delete">
                                                <Box>
                                                    <DeleteOutline className='icons text-grey' onClick={() => handleOpen(row._id)} />
                                                </Box>
                                            </Tooltip>
                                            <Tooltip title="More">
                                                <Box>
                                                    
                                                        <MoreVertIcon className='icons text-grey' onClick={(e) => handleMore(e, row._id)}/>
                                                    
                                                </Box>
                                            </Tooltip>
                                            <Tooltip title="Copy URL">
                                                <Box>
                                                    <CiLink className='icons text-grey' onClick={(e) => handleCopy(e, row.unique_link)} />
                                                </Box>
                                            </Tooltip>
                                        </Box>
                                    </TableCell>  
                                    

                                        {/* <StyledTableCell
                                            align="left"
                                            className="DT-row"
                                        >
                                            <FormControlLabel
                                            className="m-0"
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                            control={
                                                <Switch
                                                size="small"
                                                onChange={(e) =>
                                                    handleChangeStatus(
                                                    e,
                                                    row,
                                                    "Public"
                                                    )
                                                }
                                                checked={row.public}
                                                sx={{ m: 1 }}
                                                />
                                            }
                                            />
                                        </StyledTableCell> */}

                                        </TableRow>
                                    </>
                                    )}
                                </Draggable>
                                ))
                            ) : (
                                <TableRow className="">
                                <TableCell
                                    className="DT-row"
                                    align="center"
                                    colSpan={5}
                                >
                                    No Data Found
                                </TableCell>
                                </TableRow>
                            )}
                            </TableBody>
                        )}
                        </Droppable>
                    </DragDropContext>

                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[8, 12, 16, 20, 24]}
                    component="div"
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className="DT-pagination"
                />

                </Box>


                
            </Box>
       
        </Box>
        }
        
        <BootstrapDialog
                onClose={handleClickClose}
                aria-labelledby="customized-dialog-title"
                open={openview}
                className='custom_modal'
                maxWidth='md'
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Community Details
                </DialogTitle>

                <DialogContent dividers>
                    <Grid container columnSpacing={2} rowSpacing={1} className=" mb-10 align-items-center">
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <div className="">
                                <Swiper
                                    modules={[Navigation, Pagination]}
                                    className="mySwiper "
                                    slidesPerView={1}
                                    pagination={{
                                        clickable: true,
                                    }}
                                >
                                    {alldata && alldata.allimages && alldata.allimages?.length > 0 && alldata.allimages.map((tdata, i) => (
                                        <SwiperSlide> <img width={'100%'} height={250} alt='Pic' src={process.env.REACT_APP_BASE_URL + `${tdata}`} /></SwiperSlide>
                                    )
                                    )}
                                </Swiper>
                            </div>

                        </Grid>

                        <Grid style={{ marginTop: '-200px' }} item xs={12} sm={12} md={6} lg={6}>

                            <Box className=''>
                                <Typography component='span' variant='body1' className='fz-15 semibold'>Specialization: </Typography>
                                <Typography component='span' variant='body1' className='fz-15 '>{alldata.course_name}</Typography>
                            </Box>

                            {/* <Box className=''>
                                <Typography component='span' variant='body1' className='fz-15 semibold'>Category: </Typography>
                                <Typography component='span' variant='body1' className='fz-15 '>{alldata.category && alldata.category?.length > 0 && alldata.category[0].category_name}</Typography>
                            </Box> */}

                            {/* {alldata.subcategory && alldata.subcategory?.length > 0 ?
                                <Box className=''>
                                    <Typography component='span' variant='body1' className='fz-15 semibold'>Sub-Category: </Typography>
                                    <Typography component='span' variant='body1' className='fz-15 '>{alldata.subcategory && alldata.subcategory?.length > 0 ? alldata.subcategory[0].subcategory_name : '-'}</Typography>
                                </Box> : ""} */}

                        </Grid>
                    </Grid>

                    <Typography component='div' variant='body1' className='fz-14 semibold'>Description: </Typography>
                    {sanitizedContent ? <div className='w-full editor_view' dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
                        : <Typography className='p18_tag' >There is no description</Typography>}

                </DialogContent>

                <DialogActions>
                    <Button className='cancel_btn' onClick={handleClickClose}>Close</Button>
                </DialogActions>
            </BootstrapDialog>
        </>
        
    );
}
