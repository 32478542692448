import { PlaylistAddRounded } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import Sidebar from './../../Sidebar'
import { useNavigate, useParams } from 'react-router-dom'
import MiniDrawer from '../../../../../components/drawer'
import DataTable from './Table_Community'

export default function Community() {
    const params = useParams()
    const auth = JSON.parse(localStorage.getItem("userData"))
    let navigate = useNavigate()
    const addCommunity = () => {
        navigate(`/teachers/details/community/add/${params.id}`);
    }
    return (
        <Box className={auth.role_id === 1 ? 'withSubmenu' : ''}>
        {auth.role_id === 1 ? <MiniDrawer menuOrder={2} submenuOrder={1} />
            : <MiniDrawer menuOrder={13} />}
        {auth.role_id === 1 ? <Sidebar subtype={6} /> : ''}
            <Box component="section" className="contentWraper">
                <Box className='headingBox'>
                    <Typography component='h4' variant='h4' className='Submenu-page-heading formTitle'>Community</Typography>
                    <Button variant="contained" className='addbtn' onClick={() => addCommunity()}> <PlaylistAddRounded className='fz-18 mr-5' /> Add Community</Button>
                </Box>
                <Box className='whiteContainer'>
                <DataTable />
                </Box>
            </Box>
        </Box>
    )
}


