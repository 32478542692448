import React, { useRef, useState } from "react";
import PropTypes from 'prop-types';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import MiniDrawer from '../../../../../components/drawer';
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, Button, IconButton, FormControlLabel, Tooltip, Card, Checkbox, Link, ListItemText, OutlinedInput, Input, Breadcrumbs, TableContainer, Select, TablePagination, MenuItem, Dialog, Typography, Grid, FormControl, TextField, DialogContent, DialogActions, DialogTitle, styled, CardContent, AccordionDetails, AccordionSummary, Accordion, Divider, RadioGroup, Radio, InputAdornment, Tabs, Tab } from '@mui/material';
import { Category, Close, Dvr, ListAlt, WidthFull } from '@mui/icons-material';
import CompareIcon from '@mui/icons-material/Compare';
import 'swiper/css';
import 'swiper/css/pagination';
import { AiOutlineInfoCircle } from 'react-icons/ai'; // Example icon from React Icons
import 'swiper/css/navigation';
import Switch from '@mui/material/Switch';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from 'draft-js-export-html';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import EmailEditor from "react-email-editor";
import DOMPurify from 'dompurify';
import Autocomplete from '@mui/material/Autocomplete';
import AutoComplete from "./Autocomplete";
import Sidebar from './../../Sidebar'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 40,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 0,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 18.5,
        height: 18.5,
    },
    '& .MuiSwitch-track': {
        borderRadius: 18.5 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;
    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#144BFE',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#144BFE',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor:
            '#144BFE',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundColor:
            '#144BFE',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <ListAlt />,
        2: <CompareIcon />,
        3: <Dvr />,
    };
    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

const steps = ['Basic Information', 'Community Information', 'Community Description'];

export default function Service_Steppyform() {
    const auth = JSON.parse(localStorage.getItem("userData"))
    const navigate = useNavigate()
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    }
    const [activeStep, setActiveStep] = React.useState(0);
    const params = useParams()
    console.log(params, "params");
    const { enqueueSnackbar } = useSnackbar()
    const [selectedPlan, setSelectedPlan] = React.useState('')
    const [community, setCommunity] = React.useState({ name: '', title: '', email: '', description: "", link: "", amount: "" })
    const [truebutton, setTruebutton] = React.useState(false)
    const [error, setError] = React.useState({ name: "", title: "", subscription: "" })
    const [categoryId, setCategoryId] = React.useState('')
    const [courseId, setCourseId] = React.useState([])
    const [userId, setUserId] = React.useState([])
    const [subcategoryId, setSubCategoryId] = React.useState('')
    const [catError, setCatError] = React.useState('')
    const [subcatError, setSubCatError] = React.useState('')
    const [courseError, setCourseError] = React.useState('')
    const [imageError, setImageError] = React.useState('')
    const [imageError2, setImageError2] = React.useState('')
    const [imageAddError, setImageAddError] = React.useState('')
    const [state1, setState1] = React.useState([])
    const [state2, setState2] = React.useState([])
    const [statesingle, setStatesingle] = React.useState([])
    const [statesingle2, setStatesingle2] = React.useState([])
    const [state3, setState3] = React.useState([])
    const [state4, setState4] = React.useState([])
    const [statesingle3, setStatesingle3] = React.useState([])
    const [statesingle4, setStatesingle4] = React.useState([])
    const [convertedContent, setConvertedContent] = React.useState(null);
    const [description, setDescription] = React.useState('')
    const [yogaCommunity, setYogaCommunity] = useState(true)
    const [richeditor, setRichtor] = React.useState("")
    const [selectedVideo, setSelectedVideo] = useState([]);
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
    const dataofeditor = `${richeditor}`
    const [category, setCategory] = React.useState([])
    const [subcat, setSubcat] = React.useState([])
    const [course, setCourse] = React.useState([])
    const [interval, setInterval] = React.useState('month')
    const [currency, setCurrency] = React.useState('INR')
    const [user, setUser] = React.useState([])
    const [videos, setVideos] = useState([])
    const [communitytype, setCommunitytype] = React.useState('')
    const [primaryCommunity, setPrimaryCommunity] = useState(false)
    const [podcast, setPodcast] = React.useState([])
    const [alldata, setAlldata] = React.useState('');
    const [plans, setPlans] = React.useState([])
    const emailEditorRef = useRef({ editor: {} });
    const [cityName, setCityName] = useState('')
    const [lat, setLat] = useState(0.00)
    const [lng, setLng] = useState(0.00)
    const [isPublic, setPublic] = React.useState(false)
    const [ads, setAds] = React.useState([])
    const [selectOwner, setSelectOwner] = useState('No')
    const [addCommunityFrom, setaddCommunityFrom] = useState('Teacher')
    const [selectedCard, setSelectedCard] = useState(null);
	const [addonsModal, setAddonsModal] = React.useState(false)
	const [numberOfBlogs, setNumberOfBlogs] = useState('');
	const [open, setOpen] = React.useState(false);
	const [eventAddons, setEventAddons] = React.useState([])
    const redirecturl = process.env.REACT_APP_REDIRECT_URL
	console.log(redirecturl,"redirecturl");

	const [amount] = useState('1000'); // Fixed amount value
	const datas = [
		{ sectionName: "Event", amount_type: "INR", amount: "30", count: "3" },
		{ sectionName: "Event", amount_type: "INR", amount: "50", count: "5" }
	];

	const handleNumberOfBlogsChange = (event) => {
		setNumberOfBlogs(event.target.value);
	};


	const handleCardClick = (item) => {
		setSelectedCard(item);
	};

	const handleSubmit = async () => {
		const body = {
			userId: auth._id,
			customerId : auth.stripeCustomerId,
			// AddOns : selectedCard // if selectedCard is an array
			AddOns: [selectedCard],   // [selectedCard] because selectedCard is an object.. If multiple items can be selected push each item into selectedCard.
			redirectUrl: process.env.REACT_APP_REDIRECT_URL +`teachers/details/community/${auth._id}`

        	}
		console.log(body,'bodybodybody')
		await axios.put(process.env.REACT_APP_BASE_URL + `subscription/UpdateAddOnsByUserid`, body,{ headers: headerlocal })
			.then(res => {
				console.log(res.data?.PaymentLink, "update response console")
				window.open(res.data?.PaymentLink)
				onClose();
			})
			.catch(err => {
				console.log(err,"UpdateAddOnsByUserid catch error")
			})
	};


	const handleClose = () => {
        setOpen(false);
    };


	const handlePurchaseAddons = async () => {
		 var someData = auth ? auth.planId?.addOns?.filter(item => item.sectionName == 'Community') : [] //display only events addons
		//var someData = auth ? auth.planId?.addOns : [] // display all addons
		setEventAddons(someData)
		setAddonsModal(true)
		setOpen(false)
	}

	const onClose = () => {
		setAddonsModal(false)
	}

    // console.log(cityName,"cityname");
    // console.log(selectedPlan, "selectedPlanselectedPlan");

    React.useEffect(() => {
        getCategory()
    }, [])

    React.useEffect(() => {
        getSubCategory()
    }, [categoryId])

    const getCategory = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `Category/GetCategory`, { headers: headerlocal }).then((res) => {
            console.log(res.data, 'res.datacategory');
            setCategory(res.data.category)
        }).catch((error) => {
            console.log(error.response, "errorin categoryyyyyyyyyyyyyy");
        })
    }

    const getSubCategory = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `SubCategory/GetSubcategorybasedonCategoryId?category_id=${categoryId}`, { headers: headerlocal })
            .then((res) => { setSubcat(res.data.subcategory) }).catch((error) => {
                console.log(error.response, "error");
            })
    }

    const handleRemoveRestart = () => {
        setSubCategoryId('')
    }

    const handleTogglePublic = () => {
        setPublic(!isPublic)
    }

    const handleEditOwner = (e) => {
        setSelectOwner(e.target.value);

    }

    //ads
    React.useEffect(() => {
        getAds()
    }, [])

    const getAds = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + `AddManagement/getAddManagement`, { headers: headerlocal });
            setAds(response.data.getAddManagement);
            console.log(response, "adssss===");
        } catch (error) {
            console.log(error.response, "error");
        }
    }

    const [selecttedAds, setSelecttedAds] = React.useState([])
    const [adsName, setAdsName] = React.useState([])
    const handlechangeAds = (event, item) => {
        // setError({ ...error, ["userselect"]: '' })
        const {
            target: { value },
        } = event;
        setAdsName(
            typeof value === 'string' ? value.split(',') : value,
        );
        getAdsval()
    }
    React.useEffect(() => {
        getAdsval()
    }, [adsName])

    function getAdsval() {
        var array = []
        const selecttedAds = ads.filter(content => adsName.includes(content.title));
        // console.log(selecttedTag,'selecttedTagselecttedTagselecttedTag');
        selecttedAds.map((item, i) => {
            var val = { title: item.title }
            Object.assign(item, val)
            array.push(val)
        })
        setSelecttedAds(array)
    }

    const onlyNumbers = (e) => { e.target.value = e.target.value.replace(/[^0-9, ,+,-]/g, '') };


    let name, value;
    const handlechange = (e) => {
        name = e.target.name;
        value = e.target.value;
        if (name === 'name') {
            value = value.trim();
        }
        setCommunity({ ...community, [name]: value })
    }

    const indexDelete = (e, index) => {
        var filteredpreview = state1.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = state2.filter((val, i) => {
            return i !== index;
        });
        setState1(filteredpreview);
        setState2(filteredraw);
    }
    const SingleImage = (e, index) => {
        setImageError('');
        setError('');

        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        fileObj.push(e.target.files);

        for (let i = 0; i < fileObj[0].length; i++) {
            const img = new Image();
            img.src = URL.createObjectURL(fileObj[0][i]);

            img.onload = function () {
                if (fileObj[0][i].size > 1000000) { // 1 MB in bytes
                    setImageError('Image size should be less than 1 MB.');
                } else {
                    fileArray.push(URL.createObjectURL(fileObj[0][i]));
                    filepath.push(fileObj[0][i]);

                    var data = statesingle.filter(x => x != null);
                    var data1 = statesingle2.filter(x => x != null);
                    setStatesingle(data);
                    setStatesingle2(data1);
                    setStatesingle(prevValue => prevValue.concat(fileArray));
                    setStatesingle2(prevValue => prevValue.concat(filepath));
                }
            };
        }
    };
    const SingleProfileImage = (e, index) => {
        setImageError2('');
        setError('');

        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        fileObj.push(e.target.files);

        for (let i = 0; i < fileObj[0].length; i++) {
            const img = new Image();
            img.src = URL.createObjectURL(fileObj[0][i]);

            img.onload = function () {
                if (fileObj[0][i].size > 1000000) { // 1 MB in bytes
                    setImageError2('Image size should be less than 1 MB.');
                } else {
                    fileArray.push(URL.createObjectURL(fileObj[0][i]));
                    filepath.push(fileObj[0][i]);

                    var data = statesingle3.filter(x => x != null);
                    var data1 = statesingle4.filter(x => x != null);
                    setStatesingle3(data);
                    setStatesingle4(data1);
                    setStatesingle3(prevValue => prevValue.concat(fileArray));
                    setStatesingle4(prevValue => prevValue.concat(filepath));
                }
            };
        }
    };
    const indexDelete2 = (e, index) => {
        var filteredpreview = statesingle3.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = statesingle4.filter((val, i) => {
            return i !== index;
        });
        setStatesingle3(filteredpreview);
        setStatesingle4(filteredraw);
    }
    const [files, setFiles] = useState([])
    const handleFileChange = (e) => {
        var fileObj = [];
        var filepath = [];
        fileObj.push(e.target.files)
        for (let i = 0; i <= fileObj[0].length; i++) {
            if (i < fileObj[0].length) {
                filepath.push(fileObj[0][i])
            }
        }
        var data = files.filter(x => x != null)
        setFiles(data)
        setFiles(prevValue => prevValue.concat(filepath))
    }
    const indexDelete1 = (e, index) => {
        var filteredpreview = statesingle.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = statesingle2.filter((val, i) => {
            return i !== index;
        });
        setStatesingle(filteredpreview);
        setStatesingle2(filteredraw);
    }

    const handleYogaCommunity = () => {
        setYogaCommunity(!yogaCommunity)
    }

    const validate = () => {
        if (!community.name && !community.title) {
            setError({ name: "This field is required.", title: "This field is required." })
            // setCatError("This field is required.")
            // setImageError("This field is required.")
            // setCourseError("This field is required.")
            return false
        }
        if (!community.name.trim()) {
            setError({ name: "This field is required." })
            return false
        }
        if (!community.name) {
            setError({ name: "This field is required." })
            return false
        }
        if (!categoryId) {
            setCatError("This field is required.")
        }
        if (!courseId) {
            setCourseError("This field is required.")
        }
        if (!community.title) {
            setError({ title: "This field is required." })
            return false
        }
        if (statesingle2?.length == 0) {
            setImageError("This field is required.")
            return false
        }
        if (statesingle4?.length == 0) {
            setImageError2("This field is required.")
            return false
        }
        return true
    }

    const handleChangeStatus = async (e) => {
        setPrimaryCommunity(e.target.checked)
    };

    const subscriptionDetails = (q) => {
        let body = {
            planId: selectedPlan.stripePlanId,
            userId: auth._id,
        };
        axios.post(process.env.REACT_APP_BASE_URL + `subscription/create-session`, body, headerlocal)
            .then((res) => {
                console.log(res.data, "SESSION");
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleAddCommunity = (e, details) => {
        // const imgrawArray = videos.map(video => video.imgraw);
        // const vdorawArray = videos.map(video => video.vdoraw);
        // var multipleImage = state2.filter(x => x != null);
        var valid = validate();
        console.log(valid, 'validvalid');
        // var validnew = validatenew();
        if (valid == true) {
            const formData = new FormData();
            formData.append('addCommunityFrom', addCommunityFrom)
            formData.append('createdby[]', params && params.id);
            formData.append("coummunity_name", community.name);
            // formData.append("description", sanitizedHtml);
            formData.append("coummunity_title", community.title);
            formData.append("short_description", description);
            formData.append("category_id", categoryId);
            formData.append("subcategory_id", subcategoryId);
            formData.append("amount", community.amount)
            formData.append("interval", interval);
            formData.append("currency", currency);
            // formData.append("addCommunityFrom", 'teacher')
            // formData.append("email", community.email);
            formData.append("community_type", communitytype);
            formData.append("community_primary", primaryCommunity);
            formData.append("planId", "");
            formData.append("yogacommunity", yogaCommunity)
            formData.append("formatted_address", cityName)
            formData.append("location_coord", cityName)
            formData.append("latitude", lat)
            formData.append("longitude", lng)
            // formData.append("advertisement", isPublic ? true : false);
            // formData.append('course_id', JSON.stringify(selecttedCourse.length > 0 ? selecttedCourse : []))
            // formData.append('user_id', JSON.stringify(selecttedUsers.length > 0 ? selecttedUsers : []))
            // formData.append('podcasts', JSON.stringify(selecttedPodcast.length > 0 ? selecttedPodcast : []))
            formData.append("unique_link", community.link);
            // formData.append("DescriptionJSON", design && JSON.stringify(design.body));
            statesingle2.forEach((item) => formData.append("image", item));
            statesingle4.forEach((item) => formData.append("profile", item));
            formData.append("adsby", selectOwner === 'Yes' ? 'Yes' : 'No');
            formData.append('ads', JSON.stringify(selecttedAds.length > 0 ? selecttedAds : []))
            // { multipleImage ? multipleImage.forEach((item) => { formData.append("additionalimages", item) }) : formData.append("additionalimages", '') }
            // { imgrawArray ? imgrawArray.forEach((item) => { formData.append("thumbnail", item) }) : formData.append("thumbnail", '') }
            // { vdorawArray ? vdorawArray.forEach((item) => { formData.append("Video", item) }) : formData.append("Video", '') }
            // { files ? files.forEach((item) => { formData.append("pdf", item) }) : formData.append("pdf", '') }

            console.log([...formData], "formdattttta")
            axios.post(process.env.REACT_APP_BASE_URL + `community/addCommunity`, formData, { headers: headerformdata })
                .then((res) => {
                    console.log(res, "community");
                    const { planId, _id } = res.data.addCommunity;
                    subscriptionDetails(_id);
                    setTruebutton(false);
                    // enqueueSnackbar("Community added successfully", { variant: 'success' });
                    // navigate(`/teachers/details/community/${params && params.id}`)
                    // setCommunity({ name: '', description: "", title: "", link: "" });
                    // setSelectedPlan('');
                    // setCategoryId('');
                    // setSubCategoryId('');
                    // setState1([]);
                    // setState2([]);
                    if (details === 'addWithNavigate') {
						setCommunity({ name: '', description: "", title: "", link: "" });
						setSelectedPlan('');
						setCategoryId('');
						setSubCategoryId('');
						setState1([]);
						setState2([]);
						navigate(`/community/community-details/desc/${res.data.addCommunity._id}`)
					} else if (details === 'add') {
						setCommunity({ name: '', description: "", title: "", link: "", amount: "" });
						setSelectedPlan('');
						setCategoryId('');
						setSubCategoryId('');
						setState1([]);
						setState2([]);
						enqueueSnackbar("Community added successfully", { variant: 'success' });
                        navigate(`/teachers/details/community/${params && params.id}`)					}
                }).catch((err) => {
                    setTruebutton(false);
                    enqueueSnackbar(err.response && err.response.data.error ? err.response.data.error : "Something went wrong.", { variant: 'error' });
                    console.log(err, "error");
                    if (err.response.data?.status == 3) {
						setOpen(true)
					}
                });
            // });
        }
    };

    React.useEffect(() => {
        getPlans()
    }, [])

    const getPlans = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `subscription/GetSubscription`, { headers: headerlocal }).then((res) => {
            setPlans(res.data.getSubscriptiondata)
        }).catch((error) => {
            console.log(error, "Error ");
        })
    }

    return (
        <Box className={auth.role_id == 1 ? 'withSubmenu' : ''}>
            {auth.role_id == 1 ? <MiniDrawer menuOrder={2} submenuOrder={1} />
                : <MiniDrawer menuOrder={13} />}
            {auth.role_id == 1 ? <Sidebar subtype={6} /> : ''}
            <Box component="section" className="contentWraper">

                <Grid item xs={12} sm={12} md={8} lg={6}  >
                    <Box className='whiteContainer p-20'>
                        <Typography component='h6' className='formTitle mb-20'>Add Community</Typography>
                        {/* 
					<Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
						{steps.map((label) => (
							<Step key={label}>
								<StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
							</Step>
						))}
					</Stepper> */}
                        {/* {activeStep === 0 && */}
                        <Box className="">

                            <Grid item xs={12} sm={12} md={8} lg={12}>
                                {/* <FormControl fullWidth >
                                    <Typography component='div' className='label'>Select Subscription <Typography component='span' className='star'>*</Typography></Typography>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectedPlan ? selectedPlan : 'sel'}
                                        className="select"
                                        onChange={(e) => { setSelectedPlan(e.target.value); setError('') }}
                                    >
                                        <MenuItem disabled value={'sel'}>Select Subscription Plan</MenuItem>
                                        {plans && plans?.length > 0 ? plans.map((data) => (
                                            <MenuItem value={data}>{data.name}</MenuItem>
                                        )) : <MenuItem disabled>{"No Plan's found"}</MenuItem>}
                                    </Select>
                                </FormControl> */}
                                {/* {error.subscription && <p style={{ color: 'red' }} className="fz-13 m-0">{error.subscription}</p>} */}

                            </Grid>

                            <Box className="mb-20">
                                <FormControl fullWidth >
                                    <Typography component='div' className='label'>Select Type of Community<Typography component='span' className='star'>*</Typography></Typography>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={communitytype ? communitytype : "sel"}
                                        placeholder="Select Community Type"
                                        className="select"
                                        onChange={(e) => { setCommunitytype(e.target.value); setError({ communitytype: "" }) }}
                                    >
                                        <MenuItem disabled value={'sel'}>Select Community Type</MenuItem>
                                        <MenuItem value={"public"}>Public Community
                                        </MenuItem>
                                        <MenuItem value={"private"}>Private Community</MenuItem>
                                        <MenuItem value={"secured"}>Secured Community</MenuItem>
                                    </Select>
                                    {/* {/ {error.job_type && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.job_type}</p>} /} */}
                                </FormControl>
                            </Box>
                            {communitytype == 'private' || communitytype == 'secured' ?

                                <><Grid item xs={12} sm={6} md={4} lg={3}>

                                    <Typography component='div' className='label'>Select Currency<Typography component='span' className='star'>*</Typography></Typography>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={currency}
                                            placeholder="Select Currency"
                                            className="select"
                                            onChange={(e) => { setCurrency(e.target.value); setError(''); }}
                                        >
                                            <MenuItem disabled>Select Currency</MenuItem>
                                            <MenuItem value={"INR"}>INR</MenuItem>
                                            <MenuItem value={"USD"}>USD</MenuItem>
                                        </Select>
                                    </FormControl>

                                </Grid><Grid item xs={12} sm={6} md={4} lg={3}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Amount<Typography component='span' className='star'></Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Amount'
                                                name="amount"
                                                onInput={(e) => onlyNumbers(e)}
                                                value={community.amount}
                                                onChange={(e) => {
                                                    handlechange(e);
                                                }} />
                                            {error.title && <p style={{ color: 'red' }} className="fz-13 m-0">{error.title}</p>}
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4} lg={3}>

                                        <Typography component='div' className='label'>Select Interval<Typography component='span' className='star'> * </Typography></Typography>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={interval ? interval : "sel"}
                                                placeholder="Select Interval"
                                                className="select"
                                                onChange={(e) => { setInterval(e.target.value); setError(''); }}
                                            >
                                                <MenuItem disabled value={'sel'}>Select Interval</MenuItem>
                                                <MenuItem value={"week"}>Weekly</MenuItem>
                                                <MenuItem value={"month"}>Monthly</MenuItem>
                                                <MenuItem value={"year"}>Yearly</MenuItem>
                                            </Select>
                                        </FormControl>
                                        {error.interval && <p style={{ color: 'red' }} className="fz-13 m-0">{error.interval}</p>}
                                    </Grid>
                                </> : ""}
                            <Box className="mb-20">
                                <Typography component='div' className='label'>
                                    <span>Community Name </span>
                                    <Typography component='span' className='star'>*</Typography>
                                    {/* Information Icon with Tooltip */}
                                    <Tooltip title="This name will be used to create a unique URL for your community">
                                        <IconButton aria-label="information">
                                            <AiOutlineInfoCircle size={20} />
                                        </IconButton>
                                    </Tooltip>
                                    <Typography component='span' style={{ fontSize: 'smaller' }}>
                                        {/* This name will be used to create a unique URL for your community */}
                                    </Typography>									</Typography>
                                <FormControl variant="standard" fullWidth>
                                    <TextField
                                        id="filled-size-small"
                                        variant="outlined"
                                        size="small"
                                        name='name'
                                        className='textfield'
                                        style={{ width: '100%' }}
                                        value={community.name}
                                        onChange={(e) => {
                                            handlechange(e);
                                            setError(prevError => ({ ...prevError, name: '' }));
                                        }}
                                    // InputProps={{
                                    // 	startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    // }}
                                    />
                                    {error.name && <p style={{ color: 'red' }} className="fz-13 m-0">{error.name}</p>}
                                </FormControl>
                            </Box>

                            <Box className="mb-20">
                                <Typography component='div' className='label'>Community Title <Typography component='span' className='star'>*</Typography></Typography>
                                <FormControl variant="standard" fullWidth>
                                    <TextField
                                        id="filled-size-small"
                                        variant="outlined"
                                        size="small"
                                        name='title'
                                        className='textfield'
                                        style={{ width: '100%' }}
                                        value={community.title}
                                        onChange={(e) => {
                                            handlechange(e); setError(prevError => ({ ...prevError, title: '' }))
                                        }}
                                    // InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment>, }}
                                    />
                                    {error.title && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.title}</p>}
                                </FormControl>
                            </Box>
                            <Box className='mb-20 flex-center'>
                                <Typography component='div' variant='body1' className='fz-14 semibold mr-10'>Primary Community:   </Typography>
                                <FormControlLabel className='m-0' control={<Switch size="small" onChange={(e) => handleChangeStatus(e)} checked={primaryCommunity} />} />
                            </Box>

                            <FormControlLabel className="checkbox_list"
                                control={
                                    <Checkbox
                                        checked={yogaCommunity}
                                        onChange={handleYogaCommunity}
                                        name='checkbox-value'
                                        color='primary'
                                    />
                                }
                                label={<Typography component='div' className='label mb-0'>Is this Community a yoga community? </Typography>}
                            />

                            {/* <FormControlLabel className="checkbox_list"
                                control={
                                    <Checkbox
                                        checked={isPublic}
                                        onChange={handleTogglePublic}
                                        name='checkbox-value'
                                        color='primary'
                                    />
                                }
                                label={<Typography component='div' className='label mb-0'>Do you want to display this ads? </Typography>}
                            /> */}
                            {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Email: <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='email'
                                                className='textfield'
                                                value={community.email}
                                                placeholder='Email'
                                                onChange={(e) => {
													handlechange(e);
												}}

                                            />
                                        </FormControl>
                                        <span style={{ color: "red", fontSize: 12 }}>{error.email}</span>
                                    </Grid> */}

                            <Box className="mb-20 ">
                                <Typography component='div' className='label'>Would you like to display the ads?</Typography>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-customized-radios"
                                    name="payment_type"
                                    onChange={handleEditOwner}
                                    value={selectOwner}
                                >
                                    <FormControlLabel
                                        value="Yes"
                                        className='radio-btns'
                                        control={<Radio />}
                                        label={<Typography className='fz-14'>Yes</Typography>}
                                    />
                                    <FormControlLabel
                                        value="No"
                                        className='radio-btns'
                                        control={<Radio />}
                                        label={<Typography className='fz-14'>No</Typography>}
                                    />
                                </RadioGroup>
                            </Box>


                            {selectOwner === 'Yes' && (
                                <Grid>
                                    <Typography component='div' className='label'>Select Ads</Typography>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            multiple
                                            className='select'
                                            value={adsName}
                                            onChange={handlechangeAds}
                                            input={<OutlinedInput />}
                                            renderValue={(selected) => {
                                                const selectedAds = ads.filter(content => selected.includes(content.title));
                                                if (selectedAds.length === 0) {
                                                    return "Any ads";
                                                } else if (selectedAds.length === 1) {
                                                    return selectedAds[0].title;
                                                } else {
                                                    return `${selectedAds.length} ads selected`;
                                                }
                                            }}
                                            MenuProps={MenuProps}
                                        >
                                            {ads && ads.length > 0 ? ads.map((name) => (
                                                <MenuItem key={name._id} value={name.title}>
                                                    <Checkbox checked={adsName.indexOf(name.title) > -1} />
                                                    <ListItemText primary={name.title} />
                                                </MenuItem>
                                            )) : <MenuItem>No ads found</MenuItem>}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}

                            <Box className="mb-20">
                                <Typography component='div' className='label'>Select Category<Typography component='span' className='star'>*</Typography></Typography>
                                <FormControl fullWidth >
                                    <Select
                                        className="select"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={categoryId ? categoryId : 'sel'}
                                        onChange={(e) => { setCategoryId(e.target.value); setCatError("") }}>
                                        <MenuItem disabled value={'sel'} >Select category</MenuItem>
                                        {category && category?.length > 0 ? category.map((data) => (
                                            <MenuItem value={data._id}>{data.category_title}</MenuItem>)) : <MenuItem disabled>{"No Record's found"}</MenuItem>}
                                    </Select>
                                    {catError && <p style={{ color: 'red' }} className="fz-13 m-0" >{catError}</p>}
                                </FormControl>
                            </Box>
                            <Box className="mb-20">
                                <Typography component='div' className='label' style={{ marginRight: '10px' }}>Select Sub Category</Typography>
                                <Box >
                                    <FormControl fullWidth style={{ flex: 1 }}>
                                        <Select
                                            className="select"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            disabled={categoryId !== '' ? false : true}
                                            value={subcategoryId ? subcategoryId : 'sel18'}
                                            onChange={(e) => {
                                                setSubCategoryId(e.target.value);
                                                setSubCatError('');
                                                setCatError('');
                                                setSubCatError('');
                                            }}
                                        >
                                            <MenuItem disabled value={'sel18'}>Select sub category</MenuItem>
                                            {subcat && subcat?.length > 0 ? (
                                                subcat.map((data5) => (
                                                    <MenuItem value={data5._id}>{data5.subcategory_title}</MenuItem>
                                                ))) : (<MenuItem disabled>{"No Record's found"}</MenuItem>)}
                                        </Select>
                                        {subcatError && (<p style={{ color: 'red' }} className="fz-13 m-0">{subcatError}</p>)}
                                    </FormControl>
                                    {subcategoryId !== '' && (
                                        <RestartAltIcon style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={() => handleRemoveRestart()} />
                                    )}
                                </Box>
                            </Box>

                            <Grid item xs={12} sm={12} md={12}  >
                                <Box className="flex-center flex-between">
                                    <Typography component='div' className='label mb-10'>Cover Image<Typography component='span' className='star'> * </Typography></Typography>
                                    <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                        {statesingle.length < 1 &&
                                            <Button className="upload_btn" variant="contained" component="label">
                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                                <input accept={"image/png, image/jpg,image/jpeg"} onChange={SingleImage} hidden type="file" />
                                            </Button>
                                        }
                                    </Box>
                                </Box>
                            </Grid>
                            {imageError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageError}</p>}

                            <Grid item xs={12} sm={12} md={12}  >
                                {statesingle.length > 0 &&
                                    <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                        {statesingle.length > 0 ? statesingle.map((x, i) => (
                                            <Grid item xs={6} sm={4} md={4} lg={4} >
                                                <Box className='uploaded_img' >
                                                    <Button className='close_icon' onClick={(e) => indexDelete1(e, i)}><Close /></Button>
                                                    <img src={x} alt='' height={155} width={195} />
                                                </Box>
                                            </Grid>
                                        )) : ""}
                                    </Grid>
                                }
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 500px * 600px for better quality picture.</Typography>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}  >
                                <Box className="flex-center flex-between">
                                    <Typography component='div' className='label mb-10'>Community Profile<Typography component='span' className='star'> * </Typography></Typography>
                                    <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                        {statesingle3.length < 1 &&
                                            <Button className="upload_btn" variant="contained" component="label">
                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                                <input accept={"image/png, image/jpg,image/jpeg"} onChange={SingleProfileImage} hidden type="file" />
                                            </Button>
                                        }
                                    </Box>
                                </Box>
                            </Grid>
                            {imageError2 && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageError2}</p>}

                            <Grid item xs={12} sm={12} md={12}  >
                                {statesingle3.length > 0 &&
                                    <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                        {statesingle3.length > 0 ? statesingle3.map((x, i) => (
                                            <Grid item xs={6} sm={4} md={4} lg={4} >
                                                <Box className='uploaded_img' >
                                                    <Button className='close_icon' onClick={(e) => indexDelete2(e, i)}><Close /></Button>
                                                    <img src={x} alt='' height={155} width={195} />
                                                </Box>
                                            </Grid>
                                        )) : ""}
                                    </Grid>
                                }
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 280px * 280px for better quality picture.</Typography>
                            </Grid>

                            {/* <Box className="mb-10">
									<Typography component='div' className='label'>Link <Typography component='span' className='star'>*</Typography></Typography>
									<FormControl variant="standard" fullWidth>
										<TextField
											id="filled-size-small"
											variant="outlined"
											placeholder='Link'
											size="small"
											name='link'
											className='textfield'
											style={{ width: '100%' }}
											value={community.link}
											onChange={(e) => {
												handlechange(e); setError(prevError => ({ ...prevError, link: '' }))
											}}
										/>
										{error.link && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.link}</p>}
									</FormControl>
								</Box> */}

                            <Box className="mb-20">
                                <Typography component='div' className='label'>Location</Typography>
                                <AutoComplete cityName={cityName} setCityName={setCityName} lat={lat} setLat={setLat} lng={lng} setLng={setLng} className="textfield" />
                            </Box>

                            <Grid item xs={12} sm={12} md={12}  >
                                <Box className="mb-10">
                                    <Typography component='div' className='label'>Short Description</Typography>

                                    <FormControl variant="standard" fullWidth>
                                        <textarea
                                            placeholder='Short Description '
                                            className='textarea'
                                            defaultValue={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            rows={4}
                                            cols={40} />
                                    </FormControl>
                                </Box>
                            </Grid>
                            {/* {
									activeStep === 0 &&
									<>
										<Box className='heading-btns mt-40' style={{ textAlign: 'end' }}>
											<Button onClick={handleNext} variant="contained" className='theme-btn  '>Next</Button>
										</Box>
									</>
								} */}


                            {truebutton === false ?
                                <>
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>

                                        {/* <Button onClick={handleAddService} variant="contained" className='theme-btn'>Submit</Button> */}
                                        <Button variant="contained" className='addbtn mr-10' onClick={(e) => handleAddCommunity(e, 'add')}>Save</Button>
                                        <Button variant="contained" className='addbtn mr-10' onClick={(e) => handleAddCommunity(e, 'addWithNavigate')}>Save + Continue</Button>
                                    </Box>
                                </>
                                :
                                truebutton === true &&
                                <>
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>

                                        <Button variant="contained" className='theme-btn  '>Please wait...</Button>
                                    </Box>
                                </>
                            }


                        </Box>
                        {/* } */}
                    </Box >
                </Grid>
                <Dialog open={addonsModal} onClose={onClose} maxWidth="sm" fullWidth>
					<DialogTitle>Select Details</DialogTitle>
					<DialogContent>
						<Grid container spacing={2}>
                        {eventAddons && eventAddons.length > 0 && eventAddons.map((item, index) => (
								<Grid item xs={12} sm={6} md={4} key={index}>
									<Card
										variant="outlined"
										sx={{
											cursor: 'pointer',
											'&:hover': { boxShadow: 3 },
											border: selectedCard?.count === item?.count ? '2px solid #1976d2' : '1px solid #e0e0e0'
										}}
										onClick={() => handleCardClick(item)}
									>
										<CardContent>
											<Typography variant="h6">{item.sectionName}</Typography>
											<Typography variant="body1">Amount Type: {item.amount_type}</Typography>
											<Typography variant="body1">Amount: {item.amount}</Typography>
											<Typography variant="body1">Count: {item?.count}</Typography>
										</CardContent>
									</Card>
								</Grid>
							))}
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button onClick={onClose} color="primary">
							Cancel
						</Button>
						<Button onClick={handleSubmit} color="primary" variant="contained" disabled={!selectedCard}>
							Submit
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog open={open} keepMounted
					onClose={handleClose}
					aria-describedby="alert-dialog-slide-description"
					className='custom_modal'>
					<DialogTitle>Would you like to purchase Community Addons?</DialogTitle>
					<DialogActions>
						<Button onClick={handleClose} className='cancel_btn'>No</Button>
						<Button onClick={handlePurchaseAddons} className='theme-btn'>Yes</Button>
					</DialogActions>
				</Dialog>

            </Box >
        </Box >
    )
}