import React, { useState } from 'react'
import { Box, Button, Grid, IconButton, Stack, TextField, Typography, styled } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import Dialog from '@mui/material/Dialog';
import playBtn from '../../../../assets/images/play.png'
import CloseIcon from '@mui/icons-material/Close';
import videoPoster from '../../../../assets/images/TeacherBanner.png'
import yoga2 from '../../../../assets/images/Jobsbanner.png'
import Header from '../../../Home/Header';
// import Menu from '../../../Menu';
import Footer from '../../../Home/Footer';
import Spacing from '../../Spacing';
import CommunityTabs from './tabs';
import { useNavigate, useLocation } from 'react-router-dom';


export default function CommunityDetails() {
    const location = useLocation()
    const [Open, setOpen] = useState(false);
    // const currentURL = window.location.href;
    // const hasMultipleImages = currentURL.includes("multiple-images");
    const community_id = location && location.state
    console.log(location.state)

    const handleOpenModal = () => {
        setOpen(true);
    };
    const handleCloseModal = () => {
        setOpen(false);
    };

    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));
    // const latestarticles = [
    //     {
    //         video: false,
    //         multiple_images: false,
    //         img: yoga2,
    //         text: "Yoga",
    //         yogatype: "Hata Yoga",
    //         date: "NOV 18, 2020",
    //         desc: "Lorem ipsum dolor sit amet, consectetur adipis cing elit.consectetur adipis cing elit.",
    //         subtext: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Duis aute irure dolor in reprehenderit."
    //     },
    //     {
    //         video: true,
    //         multiple_images: false,
    //         img: yoga2,
    //         text: "Yoga",
    //         yogatype: "Hata Yoga",
    //         date: "NOV 18, 2020",
    //         desc: "Lorem ipsum dolor sit amet, consectetur adipis cing elit.consectetur adipis cing elit.",
    //         subtext: "Duis aute irure dolor in reprehenderit in voluptate velit."
    //     },
    //     {
    //         video: false,
    //         multiple_images: false,
    //         img: yoga2,
    //         text: "Yoga",
    //         yogatype: "Hata Yoga",
    //         date: "NOV 18, 2020",
    //         desc: "Lorem ipsum dolor sit amet, consectetur adipis cing elit.consectetur adipis cing elit.",
    //         subtext: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Duis aute irure dolor in reprehenderit."
    //     },
    //     {
    //         video: false,
    //         multiple_images: true,
    //         img: yoga2,
    //         text: "Yoga",
    //         yogatype: "Hata Yoga",
    //         date: "NOV 18, 2020",
    //         desc: "Lorem ipsum dolor sit amet, consectetur adipis cing elit.consectetur adipis cing elit.",
    //         subtext: "Duis aute irure dolor in reprehenderit in voluptate velit."
    //     },
    //     {
    //         video: false,
    //         img: yoga2,
    //         multiple_images: false,
    //         text: "Yoga",
    //         yogatype: "Hata Yoga",
    //         date: "NOV 18, 2020",
    //         desc: "Lorem ipsum dolor sit amet, consectetur adipis cing elit.consectetur adipis cing elit.",
    //         subtext: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Duis aute irure dolor in reprehenderit."
    //     },
    //     {
    //         video: true,
    //         img: yoga2,
    //         multiple_images: false,
    //         text: "Yoga",
    //         yogatype: "Hata Yoga",
    //         date: "NOV 18, 2020",
    //         desc: "Lorem ipsum dolor sit amet, consectetur adipis cing elit.consectetur adipis cing elit.",
    //         subtext: "Duis aute irure dolor in reprehenderit in voluptate velit."
    //     },
    // ]
    return (
        <Box className='community_details'>
            {/* <Header /> */}
            {/* <Menu /> */}

            <Box className="container ">
                {/* <Box className="video_banner">
                    {hasMultipleImages ?
                        latestarticles !== 'undefined' && latestarticles.map((item, index) => (
                            item.multiple_images ?
                                <Swiper
                                    spaceBetween={30}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    // autoplay={{
                                    //     delay: 5000, 
                                    //     disableOnInteraction: false, 
                                    // }}
                                    modules={[Pagination]}
                                    className="mySwiper"

                                >
                                    <SwiperSlide>
                                        <img src={item.img} />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={item.img} />
                                    </SwiperSlide>
                                </Swiper>
                                : null
                        ))
                        :
                        <>
                            <video
                                loop
                                muted
                                width='100%'
                                poster={videoPoster}
                            >
                            </video> 
                            <Button disableRipple onClick={() => handleOpenModal()} className='playbtn'><img src={playBtn} /></Button>
                        </>
                    }
                </Box> */}
                <CommunityTabs community_id={community_id} />
            </Box>
            <Spacing lg="70" md="40" />

            <BootstrapDialog
                onClose={handleCloseModal}
                aria-labelledby="customized-dialog-title"
                open={Open}
                fullWidth
                maxWidth="xl"
                className='fullscreen_modal'
            >
                <IconButton
                    aria-label="close"
                    onClick={handleCloseModal}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'white',
                        zIndex: 1
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Box>
                    <iframe width="100%" height="600px" src="https://www.youtube.com/embed/v7AYKMP6rOE" frameborder="0" allowfullscreen></iframe>

                </Box>
            </BootstrapDialog>
            <Footer />
        </Box>
    )
}