import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputAdornment, InputLabel, ListItemText, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MiniDrawer from '../../../components/drawer'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

import dayjs from 'dayjs';
import axios from 'axios'
import EditBundle from './EditBundle';

const Editcoupon = () => {
    const params = useParams()
    const location = useLocation();
    const navigate = useNavigate()
    const { couponData } = location.state || {}; // Use optional chaining for safety
   

    const headerlocal = {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("token")
  }

    const [communities, setCommunities] = useState([]);
    const [yogaFans, setYogaFans] = useState([]);
    const [courses, setCourses] = useState([]);
    const [loading,setLoading] = useState('false')


  const [minimumAmount, setMinimumAmount] = useState(couponData.minimum_amount)


      // Inside your component:
  const [amountInYearAmount, setAmountInYearAmount] = useState(0);  // default to checked
  const [amountInMonthAmount, setAmountInMonthAmount] = useState(0);
  

  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedItem, setSelectedItem] = useState('');

  const [selectedCommunity, setSelectedCommunity] = useState([]);
  const [selectedYogaFans, setSelectedYogaFans] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedItemObject, setSelectedItemObject] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState(couponData.paymentType);

  const [selectedCommunities, setSelectedCommunities] = useState(couponData.communities);  // holds selected community IDs
  // const [communitiesforBundle, setCommunitiesForBundle] = useState(couponData.communities);

    const [selectedYogaFanPages, setSelectedYogaFanPages] = useState([]);
    const [selectedCoursess, setSelectedCoursess] = useState([]);


    const [selectedItemsObjectFans, setSelectedItemsObjectFans] = useState([]);
    const [selectedItemsObjectComm, setSelectedItemsObjectComm] = useState([]);
    const [selectedItemsObjectCourse, setSelectedItemsObjectCourse] = useState([]);
  

  useEffect(() => {
    // Function to determine the selected category based on the array lengths
    const setCategoryBasedOnArrays = () => {
      const { communities, fanspages, courses } = couponData;
      
      // Check which array has values and set selectedCategory accordingly
      if(couponData.offerType !== "bundle_Level"){
        if (communities.length > 0) {
          setSelectedCategory('Communities');
          setSelectedItem(communities[0])
        } else if (fanspages.length > 0) {
          setSelectedCategory('Yoga-Fans');
          setSelectedItem(fanspages[0])
  
        } else if (courses.length > 0) {
          setSelectedCategory('Courses');
          setSelectedItem(courses[0])
  
        } else {
          setSelectedCategory(''); // If all arrays are empty, clear selectedCategory
        }
      }
      else{
        if (communities.length > 0) {
          // setSelectedCategory('Communities');
          // setSelectedItem(communities[0])
        } else if (fanspages.length > 0) {
          // setSelectedCategory('Yoga-Fans');
          // setSelectedItem(fanspages[0])
  
        } else if (courses.length > 0) {
          // setSelectedCategory('Courses');
          // setSelectedItem(courses[0])
  
        } else {
          setSelectedCategory(''); // If all arrays are empty, clear selectedCategory
        }
      }
      
    };
    

    setCategoryBasedOnArrays(); // Set the category when the couponData changes
  }, [couponData]);



  
  const [errors, setErrors] = useState({
    couponCode: "",
    selectedDiscount: "",
    discountValue: "",
    selectedCommunity: "",
    selectedYogaFans: "",
    selectedCourses: "",
    minimumAmount:'',
    selectedCategory:"",
    selectedItem:"",
    expiresAt:"",
    activateNow: '',
    paymentPlan:"",
    notes:''
  });
    
    const [coupon, setCoupon] = useState({
        selectedOffer:"",
        coupon_code: '',
        discountType: '',
        discountAmount: '',
        activate: dayjs(),
        expiresAt: dayjs(),
        communities: [],
        fanspages: [],
        amountInYear:0,
        amountInMonth:0,
        minimum_amount:0,
        paymentType: '',
        courses: [],
        notes: '',
    });

    useEffect(() => {
        if (couponData) {
            setCoupon({
                selectedOffer:couponData.offerType,
                coupon_code: couponData.coupon_code,
                discountType: couponData.discountType,
                discountAmount: couponData.discountAmount,
                communities: couponData.communities,
                fanspages: couponData.fanspages,
                amountInYear: couponData?.amountInYear ,
                amountInMonth: couponData?.amountInMonth,
                courses: couponData.courses,
                minimum_amount:couponData?.minimum_amount,
                paymentType: couponData.paymentType,
                notes: couponData?.notes,
            });
        }

    }, [couponData]);

    useEffect(() => {
        const initializeValues = () => {
            if (couponData) {
                // Set selected values from coupon data received from location.state
                // setSelectedCommunities(couponData.communities);
                setSelectedCommunity(couponData.communities);
                setSelectedYogaFans(couponData.fanspages);
                setSelectedCourses(couponData.courses);
            }
        };

        initializeValues();
        setLoading(false);
    }, [couponData]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch communities, fans pages, and courses
                await Promise.all([
                    getCommunity(),
                    getFans(),
                    getCourses()
                ]);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [params]);

    const getCommunity = async () => {
        const res = await axios.get(process.env.REACT_APP_BASE_URL +
          `Community/getCommunity`, { headers: headerlocal })
        const discountOptions = res.data.community.filter(community => community.community_type !== 'public');
        setCommunities(discountOptions);
      };
    
      const getFans = async () => {
        const res = await axios.get(process.env.REACT_APP_BASE_URL + `FanPage/GettingallfansPages`);
        const paidFanspage = res.data.fansPages.filter(fan => fan.fanspage_paymentype !== 'free');
        setYogaFans(paidFanspage);
      };
    
      const getCourses = async () => {
        const res = await axios.get(process.env.REACT_APP_BASE_URL + `Course1/GetCourseallpublic`);
        const paidCourses = res.data.course.filter(course => course.course_paymenttype !== 'free');
        setCourses(paidCourses);
      };

      // const handleChangeCommunity = (event) => {
      //   const {
      //     target: { value },
      //   } = event;
      //   setSelectedCommunity(typeof value === 'string' ? value.split(',') : value);
      // };
    
      // const handleChangeYogaFans = (event) => {
      //   const {
      //     target: { value },
      //   } = event;
      //   setSelectedYogaFans(typeof value === 'string' ? value.split(',') : value);
      // };
    
      // const handleChangeCourses = (event) => {
      //   const {
      //     target: { value },
      //   } = event;
      //   setSelectedCourses(typeof value === 'string' ? value.split(',') : value);
      // };

      const handleChangeMinimumAmount = (e) =>{
        setMinimumAmount(e.target.value);
      }

      const handleInputChange = (field) => (e) => {
        setCoupon({ ...coupon, [field]: e.target.value });
        setErrors((prev) => ({ ...prev, [field]: "" })); // Clear error for this field
      };


      const handleUpdateCommunity = (newSelectedCommunity) => {
        setSelectedCommunity(newSelectedCommunity);
      };

      
    
      const handleUpdateYogaFans = (newSelectedYogaFans) => {
        setSelectedYogaFans(newSelectedYogaFans);
      };

      

    
      const handleUpdateCourses = (newSelectedCourses) => {
        setSelectedCourses(newSelectedCourses);
      };


      

    // const handleDateChange = (field) => (newValue) => {
    //     setCoupon({ ...coupon, [field]: newValue });
    //     setErrors((prev) => ({ ...prev, [field]: "" })); // Clear error for this field
    // };

    const renderValue = (selected, options, placeholder) => {
      if (selected.length === 0) return placeholder; // Show a placeholder when nothing is selected
      
      // Map the selected IDs to their community names
      const selectedLabels = options
        .filter(option => selected.includes(option._id))
        .map(option => option.coummunity_name); // Assuming 'name' is the correct field
      
      return selectedLabels.join(', '); // Join the names with a comma and space
    };

    const handleChangeCommunity = (event) => {
      const { value } = event.target;
    
      // `value` will be an array of selected community IDs
      setSelectedCommunities(value);
    
      // Find the selected communities using the IDs
      const selectedObjects = communities.filter((community) => value.includes(community._id));
    
      // Set the full objects in selectedItemsObjectComm state
      setSelectedItemsObjectComm(selectedObjects);
    };
    
    
  

    const [selectedItemsPaymentComm, setSelectedItemsPaymentComm] = useState([]); // State to store payment plans for each fan item

  
    const handlePaymentChangeForCommunity = (event,id) => {
      const selectedPaymentPlan = event.target.value;
    // Get the selected community's details using the id
    const selectedCommunity = selectedItemsObjectComm.find(item => item._id === id);
   
  
    // Create the updated payment plan object for this specific community
    const updatedPaymentPlan = {
      _id: selectedCommunity._id,
      name: selectedCommunity.coummunity_name,
      // amount: selectedCommunity.interval === "week" ? selectedCommunity.amount * 52: selectedCommunity.interval === "month" ? selectedCommunity.amount * 12 : selectedCommunity.amount,
      amount: selectedCommunity.amount,
  
  
      billingCycle: selectedCommunity.interval, // You might want to update the interval here if needed
      paymentPlan: selectedPaymentPlan, // The selected plan (week/month/year)
    };
  
  
     // Now check if this community already has a payment plan in the selectedItemsPaymentComm
    // If yes, update the payment plan; if no, add the initial plan to the array
    setSelectedItemsPaymentComm(prevState => {
      const updatedState = [...prevState];
  
      const existingIndex = updatedState.findIndex(item => item._id === id);
  
      if (existingIndex !== -1) {
        // Update the payment plan for the existing community item
        updatedState[existingIndex] = updatedPaymentPlan;
      } else {
        // If the payment plan for this community does not exist, add it to the state
        updatedState.push(updatedPaymentPlan);
      }
      return updatedState;
    });
     
  
    // Reset error message for payment plan
    setErrors(prevErrors => ({ ...prevErrors, paymentPlan: '' }));
    };


    const modifyString = str => str.split(" ").map((word, index) => index === 0 ? word.slice(0, -1) : word).join(" ");


    const validateFields = () => {
        const newErrors = { ...errors }; // Start with current errors
    
        // Reset errors
        Object.keys(newErrors).forEach(key => {
          newErrors[key] = "";
        });
    
        // Check required fields
        if (!coupon.selectedOffer || coupon.selectedOffer === "sel") {
          newErrors.selectedOffer = "Offer type is required.";
        }

        
    
        if (coupon.selectedOffer === "item_Level") {
          if (!coupon.coupon_code) {
            newErrors.couponCode = "Coupon code is required.";
          }
    
          if (!coupon.discountType || coupon.discountType === "sel") {
            newErrors.selectedDiscount = "Discount type is required.";
          }
    
          if (!coupon.discountAmount) {
            newErrors.discountValue = "Discount value is required.";
          }
    
          if (!coupon.notes) {
            newErrors.notes = "Description is required.";
          }

          if(!selectedCategory){
            newErrors.selectedCategory = "Category is required.";
          }
    
          if(selectedCategory){
            if(!selectedItem){
              newErrors.selectedItem = `${modifyString(selectedCategory)} is required`;
            }
          }
    
          if(selectedCategory === "Yoga-Fans"){
            if(selectedItem){
              if(!selectedPayment){
    
                newErrors.paymentPlan = `Payment Plan is required`;
              }
            }
          }
    
          // // Validate "Expires At"
          // if (!coupon.expiresAt) {
          //   newErrors.expiresAt = "Expiration date is required.";
          // } 
          // // Validate "Activate Now"
          // if (!coupon.activate) {
          //   newErrors.activateNow = "Activation time is required.";
          // }
        }else{
          if (!coupon.coupon_code) {
            newErrors.couponCode = "Coupon code is required.";
          }
    
          if (!coupon.discountType || coupon.discountType === "sel") {
            newErrors.selectedDiscount = "Discount type is required.";
          }
    
          if (!coupon.discountAmount) {
            newErrors.discountValue = "Discount value is required.";
          }
    
          if (!coupon.notes) {
            newErrors.notes = "Description is required.";
          }
          //  // Validate "Expires At"
          //  if (!coupon.expiresAt) {
          //   newErrors.expiresAt = "Expiration date is required.";
          // } 
          // // Validate "Activate Now"
          // if (!coupon.activate) {
          //   newErrors.activateNow = "Activation time is required.";
          // }
        }
    
    
    
        setErrors(newErrors);
        return newErrors;
      };

      const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
        setSelectedItem(''); // Reset selected item when category changes
        setSelectedItemObject(null); // Reset the object of selected item
      };
    
      // Handle item change (community, yoga-fan or course)
      const handleItemChange = (event) => {
        const value = event.target.value;
        setSelectedPayment("")
        setSelectedItem({_id:value});
        // setSelectedYogaFans([{_id:value}])
        

        
    
        // Find the selected item object based on the category and value
        let selectedObject = null;
        if (selectedCategory === 'Communities') {
          setSelectedCommunity([{_id:value}])
          setSelectedCourses([])
          setSelectedYogaFans([])
          selectedObject = communities.find(option => option._id === value);
        } else if (selectedCategory === 'Yoga-Fans') {
          setSelectedYogaFans([{_id:value}])
          setSelectedCommunity()
          setSelectedCourses([])
         
          selectedObject = yogaFans.find(option => option._id === value);
        } else if (selectedCategory === 'Courses') {
          setSelectedCourses([{_id:value}])
          setSelectedCommunity([])         
          setSelectedYogaFans([])
          selectedObject = courses.find(option => option._id === value);
        }
        setSelectedItemObject(selectedObject);  

        setCoupon(prevCoupon => {
          const updatedCoupon = {
            ...prevCoupon,
            // [name]: value, // Update the property that is changed
      
            // If the selectedItemObject has `amountInYear` and `amountInMonth`, update those too
            ...(selectedItemObject?.amountInYear && { amountInYear: selectedItemObject.amountInYear }),
            ...(selectedItemObject?.amountInMonth && { amountInMonth: selectedItemObject.amountInMonth }),
          };
      
          return updatedCoupon;
        });
      };


      


      
    

   

 // Render options based on selected category
 const renderOptions = () => {
  let options = [];
  if (selectedCategory === 'Communities') {
    options = communities;
  } else if (selectedCategory === 'Yoga-Fans') {
    options = yogaFans;
  } else if (selectedCategory === 'Courses') {
    options = courses;
  }
  

  return options.map((option) => (
    <MenuItem key={option._id} value={option._id}>
      <Checkbox checked={selectedItem && selectedItem._id === option._id} />
      <ListItemText primary={option.coummunity_title || option.name || option.course_title} />
    </MenuItem>
  ));
};


const getSelectedItemName = () => {
  const selectedOption = (selectedCategory === 'Communities' ? communities :
    selectedCategory === 'Yoga-Fans' ? yogaFans : courses
  ).find(option => option._id === selectedItem._id);

  return selectedOption ? selectedOption.coummunity_title || selectedOption.name || selectedOption.course_title : '';
};


// Handle payment selection (Radio button change)
  const handlePaymentChange = (event) => {
    setSelectedPayment(event.target.value);
    setAmountInYearAmount(coupon.amountInYear);
    setAmountInMonthAmount(coupon.amountInMonth);
  };



  function calculateTotalPrice(communities,courses,fanspages,discountType,discountAmount) {
    // Initialize sums for each category
    let communitySum = 0;
    let courseSum = 0;
    let fanspageSum = 0;

    
    // 1. Calculate the sum for communities
    communities.forEach(community => {
     
      if (community.paymentPlan === 'week') communitySum += community.amount * 52;
      if (community.paymentPlan === 'month') communitySum += community.amount * 12;
      if (community.paymentPlan === 'year') communitySum += community.amount;
    });

  
    // 2. Calculate the sum for courses
    courses.forEach(course => {
      if (course.amountInMonth) courseSum += course.amountInMonth * 12;
      if (course.amountInWeek) courseSum += course.amountInWeek * 52;
      if (course.amountInBiWeek) courseSum += course.amountInBiWeek * 26;
      if (course.amountInOneTime) courseSum += course.amountInOneTime;
    });

  

    // 3. Calculate the sum for fan pages
    fanspages.forEach(fanspage => {
        if (fanspage.amountInYear) fanspageSum += fanspage.amountInYear;
      if (fanspage.amountInMonth) fanspageSum += fanspage.amountInMonth * 12;
    });

  

  
    // 4. Calculate the total sum
    let totalSum = communitySum + courseSum + fanspageSum;
  
    // 5. Apply discount based on discount type
    let finalDiscountedPrice = totalSum;
    if (discountType === "flatDiscount") {
      finalDiscountedPrice = totalSum - (parseFloat(discountAmount) || 0);
    } else if (discountType === "Percentage") {
      finalDiscountedPrice = Math.floor(totalSum - (totalSum * (parseFloat(discountAmount) / 100)));
    }
  
    // 6. Return the result with the total and discounted price
    return [
      {
        originalSum: totalSum,
        discountedPrice: finalDiscountedPrice,
        discountType: coupon.discountType,
          discountAmount: coupon.discountAmount,
      }
    ];
  }

    const handleUpdate = async (event) => {
        event.preventDefault();
        


      const result = calculateTotalPrice(selectedCommunity, selectedCourses, selectedYogaFans, coupon.discountType, coupon.discountAmount);
      
      
        const validationErrors = validateFields();
      
        if (Object.keys(validationErrors).some(key => validationErrors[key])) {
          return; // Exit if there are validation errors
        }
      
        
       
        // Prepare data for update
        const data = {
        //   offerType: coupon.selectedOffer,
          coupon_code: coupon.coupon_code,
          discountType: coupon.discountType,
          discountAmount: coupon.discountAmount,
          communities: selectedCommunity,
          fanspages: selectedYogaFans,
          courses: selectedCourses,
          bundle_price:result,
          paymentType:selectedPayment,
          amountInMonth:amountInMonthAmount || coupon.amountInMonth,
          minimum_amount:minimumAmount ,
          amountInYear:amountInYearAmount || coupon.amountInYear,
          // expiresAt: coupon.expiresAt,
          // activate: coupon.activate,
          notes: coupon.notes,
          createdby: params.id
        };
      
        console.log(data)
        
        try {
          // Use the appropriate API endpoint for updating a coupon
          const response = await axios.put(process.env.REACT_APP_BASE_URL + `Coupon/UpdateCoupons/${couponData._id}`, data);
          
          navigate(`/settings/coupons/${params.id}`);
          // Handle success (e.g., show a success message, etc.)
      
        } catch (error) {
          console.error('Update Error:', error);

          // Handle error (e.g., show an error message)
        }
      };

   

  return (
    <>
    <Box className='companies'>
        
        <MiniDrawer menuOrder={23} submenuOrder={26}/>
        <Box component="section" className="contentWraper">
                  <Box className='headingBox'>
                      <Typography component='h4' variant='h4' className='Submenu-page-heading formTitle'>Settings</Typography>
                  </Box>
                <Box className='whiteContainer' sx={{minHeight:"80vh"}}>
                    <Grid item xs={12} sm={6} md={6} lg={12}>
                        <Box className="">
                            <Box className="mb-10  p-10">
                                <Typography component='h4' variant='h4' className='Submenu-page-heading formTitle'>Edit Promos and Offers</Typography>
                            </Box>

                            <Box className="mb-20 p-10">
                                <Box className="mb-10 p-20 whiteContainer ">
                                <form onSubmit={handleUpdate}>
                                    <FormGroup>
                                    <Box className="mb-20 ">
                                          <Typography component='div' className='label'>
                                              <span>Coupon Code</span>
                                              <Typography component='span' className='star'>*</Typography>
                                              <Typography component='span' style={{ fontSize: 'smaller' }}>
                                                  {/* This name will be used to create a unique URL for your community */}
                                              </Typography>
                                          </Typography>
                                          <FormControl variant="standard" className='mb-10'>
                                              {/* <TextField
                                                  id="filled-size-small"
                                                  variant="outlined"
                                                  size="small"
                                                  name='name'
                                                  className='textfield'
                                                  style={{ width: '500px' }}
                                                  value={couponCode}
                                                  onChange={(e) => {
                                                      setCouponCode(e.target.value);
                                                      setErrors(prev => ({ ...prev, couponCode: "" }));
                                                  }}
                                              
                                              /> */}
                                              <TextField
                                                  id="coupon-code"
                                                  variant="outlined"
                                                  size="small"
                                                  name='coupon_code'
                                                  className='textfield'
                                                  style={{ width: '500px' }}
                                                  value={coupon.coupon_code}
                                                  onChange={(e) =>{

                                                      setCoupon({ ...coupon, coupon_code: e.target.value });
                                                      setErrors(prev => ({...prev, couponCode: "" }));
                                                    }
                                                  } 
                                              />
                                              {errors.couponCode && <p style={{ color: 'red' }} className="fz-13 m-0">{errors.couponCode}</p>}
                                          </FormControl>

                                          <Typography component='div' className='label'>
                                              Choose Discount Type<Typography component='span' className='star'>*</Typography>
                                          </Typography>
                                          <FormControl className='mb-20' >
                                              {/* <Select
                                                  labelId="demo-simple-select-label"
                                                  id="demo-simple-select"
                                                  value={coupon.discountType}

                                                  value={selectedDiscount ? selectedDiscount : "sel"}
                                                  placeholder="Select Discount Type"
                                                  className="select"
                                                  sx={{
                                                      minWidth: "500px"
                                                  }}
                                                  onChange={handleChangeDiscountType}
                                              >
                                                  <MenuItem disabled value={'sel'}>Select Discount Type</MenuItem>
                                                  <MenuItem value={"flatDiscount"}>Flat Discount</MenuItem>
                                                  <MenuItem value={"Percentage"}>Percentage</MenuItem>
                                              </Select> */}
                                              <Select
                                                  labelId="discount-type-label"
                                                  id="discount-type"
                                                  value={coupon.discountType}
                                                  onChange={(e) => setCoupon({ ...coupon, discountType: e.target.value })}
                                                  sx={{ minWidth: "500px" }}
                                              >
                                                  <MenuItem disabled value=''>Select Discount Type</MenuItem>
                                                  <MenuItem value="flatDiscount">Flat Discount</MenuItem>
                                                  <MenuItem value="Percentage">Percentage</MenuItem>
                                              </Select>
                                              {errors.selectedDiscount && (
                                                  <p style={{ color: 'red' }} className="fz-13 m-0">
                                                      {errors.selectedDiscount}
                                                  </p>
                                              )}
                                          </FormControl>

                                          <Typography component='div' className='label'>
                                              <span>Discount Value</span>
                                              <Typography component='span' className='star'>*</Typography>
                                              <Typography component='span' style={{ fontSize: 'smaller' }}>
                                                  {/* This name will be used to create a unique URL for your community */}
                                              </Typography>
                                          </Typography>
                                          <FormControl variant="standard" className='mb-10'>
                                              {/* <TextField
                                                //   id="filled-size-small"
                                                //   variant="outlined"
                                                //   size="small"
                                                //   name='name'
                                                //   className='textfield'
                                                //   style={{ width: '500px' }}
                                                //   value={discountValue}
                                                //   onChange={handleChangeDiscountValue}
                                                //   InputProps={{
                                                //       startAdornment: selectedDiscount === "Percentage" ? (
                                                //           <InputAdornment position="start">%</InputAdornment>
                                                //       ) : <InputAdornment position="start">₹</InputAdornment>,
                                                //   }}
                                              /> */}
                                                <TextField
                                                    id="discount-value"
                                                    variant="outlined"
                                                    size="small"
                                                    name='discount_amount'
                                                    className='textfield'
                                                    style={{ width: '500px' }}
                                                    value={coupon.discountAmount}
                                                    onChange={handleInputChange('discountAmount')}
                                                    InputProps={{
                                                              startAdornment: coupon.discountType === "Percentage" ? (
                                                                  <InputAdornment position="start">%</InputAdornment>
                                                              ) : <InputAdornment position="start">₹</InputAdornment>,
                                                          }}
                                                />
                                              {errors.discountValue && (
                                                  <p style={{ color: 'red' }} className="fz-13 m-0">
                                                      {errors.discountValue}
                                                  </p>
                                              )}
                                          </FormControl>


                          {coupon.selectedOffer === "cart_Level" &&
                            <>
                            <Typography component='div' className='label'>
                            <span>Minimum Amount</span>
                            <Typography component='span' className='star'>*</Typography>
                            <Typography component='span' style={{ fontSize: 'smaller' }}>
                              {/* This name will be used to create a unique URL for your community */}
                            </Typography>
                          </Typography>
                          <FormControl variant="standard" className='mb-10'>
                            <TextField
                              id="filled-size-small"
                              variant="outlined"
                              size="small"
                              name='name'
                              className='textfield'
                              style={{ width: '500px' }}
                              value={minimumAmount}
                              onChange={handleChangeMinimumAmount}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>
                              }}
                            />
                            {errors.minimumAmount && (
                              <p style={{ color: 'red' }} className="fz-13 m-0">
                                {errors.minimumAmount}
                              </p>
                            )}
                          </FormControl>
                            </>
                          }
                          

                                                  

                          { coupon.selectedOffer === 'item_Level' && 
                            <>
                            <Typography component="div" className="label">
                            <span>Select Category</span>
                            <Typography component="span" className="star">*</Typography>
                          </Typography>
                          <FormControl className="mb-20 mt-5">
                            <InputLabel id="category-select-label">Select Category</InputLabel>
                            <Select
                              labelId="category-select-label"
                              id="category-select"
                              value={selectedCategory}
                              onChange={handleCategoryChange}
                              sx={{ minWidth: '500px' }}
                            >
                              <MenuItem value="Communities">Communities</MenuItem>
                              <MenuItem value="Yoga-Fans">Yoga-Fans Pages</MenuItem>
                              <MenuItem value="Courses">Courses</MenuItem>
                            </Select>
                          </FormControl>
                            </>
                          }

                          {/* Conditionally Render Based on Selected Category */}
                          {selectedCategory && (
                            <>
                              <Typography component="div" className="label">
                                <span>{`Select ${selectedCategory}`}</span>
                                <Typography component="span" className="star">*</Typography>
                              </Typography>
                              <FormControl className="mb-20 mt-5">
                                <InputLabel id="item-select-label">Select {selectedCategory}</InputLabel>
                                <Select
                                  labelId="item-select-label"
                                  id="item-select"
                                  value={selectedItem ? selectedItem._id : ''}
                                  onChange={handleItemChange}
                                  renderValue={getSelectedItemName} // Display the selected item's name
                                  sx={{ minWidth: '500px' }}
                                >
                                  {renderOptions()}
                                </Select>
                                {errors.selectedItem && (
                                  <p style={{ color: 'red' }} className="fz-13 m-0">
                                    {errors.selectedItem}
                                  </p>
                                )}
                              </FormControl>
                            </>
                          )}

                          {/* Payment Amount Section */}
                          {(selectedCategory === 'Yoga-Fans' ) && (
                            <>
                              <Typography component="div" className="label">
                                <span>Select Payment Amount</span>
                              </Typography>
                              <FormControl className="mb-20 mt-5">
                                <FormLabel component="legend" className="label">
                                  Choose Payment Plan:
                                </FormLabel>
                                <RadioGroup
                                  value={selectedPayment}
                                  onChange={handlePaymentChange}
                                >
                                  { coupon.amountInYear ? (
                                    <FormControlLabel
                                      value="year"
                                      control={<Radio />}
                                      label={`Amount In Year: ${coupon.amountInYear} `}
                                    />
                                  ) : (
                                    <Typography component="div" className="label">
                                      <span>Amount In Year: No Amount Available</span>
                                    </Typography>
                                  )}
                                  {coupon.amountInMonth? (
                                    <FormControlLabel
                                      value="month"
                                      control={<Radio />}
                                      label={`Amount In Month: ${coupon.amountInMonth} `}
                                    />
                                  ) : (
                                    
                                    <Typography component="div" className="label">
                                      <span>Amount In Month: No Amount Available</span>
                                    </Typography>
                                  )}
                                </RadioGroup>
                                {errors.selectedItem && (
                                  <p style={{ color: 'red' }} className="fz-13 m-0">
                                    {errors.selectedItem}
                                  </p>
                                )}
                              </FormControl>
                            </>
                          )}

                          {
                            coupon.selectedOffer === 'bundle_Level' && <EditBundle
                              selectedCommunity={selectedCommunity}
                              selectedYogaFans={selectedYogaFans}
                              selectedCourses={selectedCourses}
                              onUpdateCommunity={handleUpdateCommunity}
                              onUpdateYogaFans={handleUpdateYogaFans}
                              onUpdateCourses={handleUpdateCourses}
                              communities={communities}
                              yogaFans={yogaFans}
                              courses= {courses}
                            />
                          }





                          <Typography component='div' className='label'>
                            <span>Description Notes</span>
                            <Typography component='span' className='star'>*</Typography>
                            <Typography component='span' style={{ fontSize: 'smaller' }}>
                              {/* This name will be used to create a unique URL for your community */}
                            </Typography>
                          </Typography>
                          <FormControl variant="standard" className='mb-10'>
                            <TextField
                              id="filled-size-small-textarea"
                              variant="outlined"
                              size="small"
                              name='notes'
                              className='textfield'
                              style={{ width: '500px' }}
                              multiline
                              rows={3} // Adjust the number of rows as needed
                              value={coupon.notes}
                              //   onChange={(e) => {
                              //       setNotes(e.target.value);
                              //       setErrors(prev => ({ ...prev, notes: "" }));
                              //   }}
                              onChange={(e) => setCoupon({ ...coupon, notes: e.target.value })}
                            />
                            {errors.notes && <p style={{ color: 'red' }} className="fz-13 m-0">{errors.notes}</p>}
                          </FormControl>
                                                 

                                      </Box>
                                    </FormGroup>
                                          <Box
                                              sx={{
                                                  display: 'flex',
                                                  justifyContent: 'flex-end',
                                                  mt: 4,
                                                  pt: 3
                                              }}
                                          >
                                              <Button type="submit" variant="contained" className='addbtn mr-10'>
                                                  Update
                                              </Button>
                                          </Box>
                                </form>
                                      
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Box>
        </Box>
    </Box>
    </>
  )
}

export default Editcoupon